import { Component, OnInit } from '@angular/core';
import { FormControl } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { Mail } from "../interfaces/mail";
import { MatDatepicker } from "@angular/material/datepicker";
import { MatDialog } from "@angular/material/dialog";
import { Moment } from 'moment';
import * as moment from 'moment';
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { CreateMailFormComponent } from "./dialog-create-mail/create-mail-form.component";
import { B2bMailsService } from "../services/b2b-mails.service";
import { Observable } from "rxjs";

@Component({
  selector: 'app-b2b-mail',
  templateUrl: './b2b-mail.component.html',
  styleUrls: ['./b2b-mail.component.scss']
})
export class B2bMailComponent implements OnInit {

  mails$: Observable<Mail[]>;
  date: FormControl = new FormControl(moment());
  templates: object[] = [];
  receiverLists: object[] = [];

  constructor(
    public http: HttpClient,
    public dialog: MatDialog,
    private mailsService: B2bMailsService
  ) { }

  ngOnInit(): void {
    this.http.get<any>('/api/b2b-mails-setup').subscribe(data => {
      this.templates = data['templates'] || [];
      this.receiverLists = data['receiverLists'] || [];
    });

    const d = new Date();
    this.getMails(
      d.getMonth() + 1,
      d.getFullYear()
    );
  }

  getMails(month: number = 0, year: number = 0) {
    this.mailsService.loadMails(month, year);
    this.mails$ = this.mailsService.get();
  }
  
  onSave(newMail: Mail) {
    this.mailsService.createMail(newMail);
  }

  onUpdate(mail: Mail) {
    this.mailsService.updateMail(mail);
  }

  onDelete(id: any) {
    this.dialog.open(ConfirmDialogComponent, {
      width: '18em',
      data: {
        title: 'Mailing löschen',
        message: 'Möchten Sie dieses Mailing unwiderruflich löschen?',
      }
    }).afterClosed().subscribe(result => {
      if (result) {
        this.mailsService.deleteMail(id);
      }
    })
  }

  onChosenMonth(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    datepicker.close();

    this.getMails(
      normalizedMonth.month() + 1,
      normalizedMonth.year()
    );
  }

  openCreateDialog() {
    const dialogRef = this.dialog.open(CreateMailFormComponent, {
      width: '36em',
      data: {
        templates: this.templates,
        receiverLists: this.receiverLists,
      },
    });

    dialogRef.afterClosed().subscribe((result: Mail) => {
      if (result) {
        this.onSave(result);
      }
    });
  }
}
