import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Mailing } from '../interfaces/mailing';
import { NotifierService } from 'angular-notifier';

@Injectable({
  providedIn: 'root'
})
export class MailingService {

  private _mailings = new BehaviorSubject<Mailing[]>([]);
  private dataStore: { mailings: Mailing[] } = { mailings: [] };

  constructor(private http: HttpClient, private notif: NotifierService) { }

  get(): Observable<Mailing[]> {
    return this._mailings.asObservable();
  }

  loadMailings(month, year): void {
    const params = new HttpParams().appendAll({
      month: month,
      year: year,
    });
    this.http.get<object[]>('/api/mailings', { params }).subscribe(
      (data: any) => {
        this.dataStore.mailings = data;
        this._mailings.next(Object.assign({}, this.dataStore).mailings);
      },
      error => console.log('Could not load mails')
    );
  }

  createMailing(mailing: Mailing): void {
    this.http.post('/api/mailings', mailing).subscribe(
      res => {
        if (res.hasOwnProperty('success') && res['success']) {
          const d = new Date();
          const md = new Date(mailing.date);

          this.notif.notify('success', 'Mailing erfolgreich angelegt');

          if (d.getFullYear() === md.getFullYear() &&
            d.getMonth() === md.getMonth() &&
            res.hasOwnProperty('id')
          ) {
            mailing.id = res['id'];
            this.dataStore.mailings.push(mailing);
            this._mailings.next(Object.assign({}, this.dataStore).mailings);
          }

          return;
        }

        this.notif.notify('error', 'Mailing konnte nicht angelegt werden');
      },
      error => console.log('Could not create mailing')
    );
  }

  updateMailing(mailing: Mailing): void {
    const id = mailing.id;
    delete mailing.id;

    this.http.put(`/api/mailings/${id}`, mailing).subscribe(
      data => {
        if (!data.hasOwnProperty('success') || !data['success']) {
          this.notif.notify('error', 'Beim Speichern ist etwas schiefgelaufen');
          return;
        }

        this.dataStore.mailings.forEach((m, i) => {
          if (m.id === id) {
            mailing.id = id;
            this.dataStore.mailings[i] = mailing;
          }
        });

        this.notif.notify('success', 'Mailing erfolgreich aktualisiert');
      },
      error => {
        console.log('Could not update mail');
        this.notif.notify('error', 'Beim Speichern ist etwas schiefgelaufen');
      }
    );
  }

  deleteMailing(mailingId: any): void {
    this.http.delete(`/api/mailings/${mailingId}`).subscribe(
      response => {
        if (!response.hasOwnProperty('success') || !response['success']) {
          this.notif.notify('error', 'Mailing konnte nicht gelöscht werden');
          return;
        }

        this.notif.notify('success', 'Mailing erfolgreich gelöscht');

        this.dataStore.mailings.forEach((m, i) => {
          if (m.id === mailingId) {
            this.dataStore.mailings.splice(i, 1);
          }

          this._mailings.next(Object.assign({}, this.dataStore).mailings);
        });
      },
      error => {
        this.notif.notify('error', 'Mailing konnte nicht gelöscht werden');
        console.log('Could not delete mailing');
      }
    );
  }
}
