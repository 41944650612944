import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { NotifierService } from 'angular-notifier';

@Injectable({
  providedIn: 'root'
})
export class UnsubscribeService {

  constructor(
    private http: HttpClient,
    private notif: NotifierService
  ) { }

  createUnsubscribe(data: any): void {
    this.http.post('/api/unsubscribe', { data: data }).subscribe(
      data => {
        if (!data.hasOwnProperty('success') || !data['success']) {
          this.notif.notify('error', 'Fields are not validated');
          return;
        }
        this.notif.notify('success', 'Request Submitted');
      },
      error => console.log('Could not create unsubscribe form')
    );
  }
}
