import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Mailing } from "../../interfaces/mailing";
import { NotifierService } from "angular-notifier";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Agency } from 'src/app/interfaces/agency';
import { Action } from 'src/app/interfaces/action';
import { Campaign } from 'src/app/interfaces/campaign';
import * as moment from 'moment';
import { AngularCsv } from 'angular-csv-ext';

@Component({
  selector: 'mailing-form',
  templateUrl: './mailing-form.component.html',
  styleUrls: ['./mailing-form.component.scss']
})
export class MailingFormComponent implements OnInit {

  @Input() mailing: Mailing;
  @Input() actions: object[];
  @Input() actionslist: Action[];
  @Input() campaignlist: Campaign[];
  @Input() agencies?: Agency[] = [];
  @Input() templates: object[];

  @Output() saveMail = new EventEmitter<Mailing>();
  @Output() updateMail = new EventEmitter<Mailing>();
  @Output() deleteMail = new EventEmitter<string>();

  sidebar = true;

  mailingForm: FormGroup;
  mailingId?: any;
  enable = false
  dropdownSettings: IDropdownSettings;

  action_id?: any;
  action_data?: any;
  action_mailing_data?: any;
  campaign_data?: any;
  agent_data?: any;
  agent_status_data?: any;
  agent_included_data?: any;
  am_required?: any;
  am_optin?: any;

  nowDate?: any;
  mailingDate?: any;
  mailingActive?: any;

  csvData?: any;
  csvRow?: any;
  csvOption?: any;

  constructor(
    public notif: NotifierService
  ) { }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      enableCheckAll: false,
      itemsShowLimit: 0,
    };

    this.mailingForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      date: new FormControl('', [Validators.required]),
      time: new FormControl('', [Validators.required]),
      cleverreach_template: new FormControl('', [Validators.required]),
      actions: new FormControl(''),
    });

    if (this.mailing !== undefined) {
      this.mailingId = this?.mailing?.id;

      this.mailingForm.patchValue({
        name: this?.mailing?.name,
        description: this?.mailing?.description,
        date: this?.mailing?.date.split(' ')[0],
        time: this?.mailing?.date.split(' ')[1],
        cleverreach_template: this?.mailing?.cleverreach_template,
        actions: this?.mailing?.actions,
      });

      this.action_id = this?.mailing?.actions[0]
      this.action_data = this.getAction(this?.action_id)
      this.campaign_data = this.getCampaign(this?.action_data?.campaign_id)
      this.agent_data = this?.campaign_data?.agencies
      this.action_mailing_data = this.getActionMailing(this?.mailing?.id)
      this.am_required = (this?.action_mailing_data?.required !== undefined) ? this?.action_mailing_data?.required : 0
      this.am_optin = (this?.action_mailing_data?.opt_in !== undefined) ? this?.action_mailing_data?.opt_in : 0

      if (this?.am_required == 1) {
        this.agent_status_data = 'Optional booked';
      } else if (this?.am_required == 0) {
        this.agent_status_data = 'Obligatory';
      } else {
        this.agent_status_data = 'Optional not booked';
      }

      if (this?.am_optin == 1) {
        this.agent_included_data = 'Yes';
      } else if (this?.am_optin == 0) {
        this.agent_included_data = 'No';
      } else {
        this.agent_included_data = '--';
      }
    }

    if (this?.mailing?.date != undefined) {
      this.nowDate = moment(moment().format('YYYY-MM-DD H:mm:ss'));
      this.mailingDate = moment(this?.mailing?.date);
      this.mailingActive = this?.nowDate.diff(this?.mailingDate, 'days');

      // console.log('mailingActive', this?.mailingActive)
    }
  }

  getAgency(id: any): Agency {
    return this.agencies.find(x => x.agencyId == id);
  }

  getAction(id: any): Agency {
    return this.actionslist.find(x => x.id == id);
  }

  getActionMailing(id: any): Agency {
    return this.action_data.mailings.find(x => x.mailing_id == id);
  }

  getCampaign(id: any): Agency {
    return this.campaignlist.find(x => x.id == id);
  }

  onSave(): void {
    if (this.mailingForm.invalid) {
      this.notif.notify('error', 'Eingaben sind unvollständig oder ungültig.');
      return;
    }

    this.saveMail.emit({
      name: this.mailingForm.get('name').value,
      description: this.mailingForm.get('description').value,
      date: this.mailingForm.get('date').value + ' ' + this.mailingForm.get('time').value,
      cleverreach_template: this.mailingForm.get('cleverreach_template').value,
      actions: [this.mailingForm.get('actions').value],
    });
  }

  onUpdate(): void {
    if (this.mailingForm.invalid) {
      this.notif.notify('error', 'Eingaben sind unvollständig oder ungültig.');
      return;
    }

    this.enable = true

    this.updateMail.emit({
      id: this.mailing.id,
      name: this.mailingForm.get('name').value,
      description: this.mailingForm.get('description').value,
      date: this.mailingForm.get('date').value + ' ' + this.mailingForm.get('time').value,
      cleverreach_template: this.mailingForm.get('cleverreach_template').value,
      actions: this.mailingForm.get('actions').value,
    });
    this.enable = false

  }

  clone(): void {
    this.enable = true

    this.saveMail.emit({
      name: this.mailing.name + ' copy',
      description: this.mailing.description,
      date: this.mailing.date,
      cleverreach_template: this.mailing.cleverreach_template,
      actions: this.mailing.actions,
    });
    this.enable = false

  }

  onDelete(): void {
    this.deleteMail.emit(this.mailing.id);
  }

  downloadCSV(): void {
    this.csvData = [];
    const d = new Date();
    var title = 'Id_' + this.mailing.id + '_' + (d.getMonth()+1) + d.getFullYear() + '_Report'

    this.csvOption = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: title,
      useBom: true,
      noDownload: false,
      headers: ["Participating", "Agencies", "Status", "Included"],
      useHeader: false,
      nullToEmptyString: true,
    };

    for (var agency of this.agent_data) {
      this.csvRow = [this.getAgency(agency).agencyId, this.getAgency(agency).name, this.agent_status_data, this.agent_included_data];

      this.csvData.push(this.csvRow);
    }

    new AngularCsv(this.csvData, title, this.csvOption);
  }

}
