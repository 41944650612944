import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Action } from '../../interfaces/action';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ActionService } from 'src/app/services/action.service';
import { CampaignService } from 'src/app/services/campaign.service';
import { MatDialog } from '@angular/material/dialog';
import { ActionStatisticsComponent } from '../action-statistics/action-statistics.component';

@Component({
  selector: 'action-form',
  templateUrl: './action-form.component.html',
  styleUrls: ['./action-form.component.scss']
})
export class ActionFormComponent implements OnInit {

  @Input() action: Action;
  @Input() campaignName: (id: any) => string;
  @Input() campaignKeys: () => string[];
  @Input() campaignMap: any;
  @Output() update = new EventEmitter<Action>();
  @Output() delete = new EventEmitter<string>();

  id: any;
  actionForm: FormGroup;
  enable = false;
  disable = false;
  createActionRes: any;

  constructor(
    private fb: FormBuilder,
    private actionService: ActionService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.id = this.action.id;
    this.initFormGroup();
  }

  initFormGroup(): void {
    const baseForm = new FormGroup({
      name: new FormControl('', []),
      description: new FormControl(''),
      campaign_id: new FormControl(''),
      included_mails: new FormControl(''),
      campaigns: new FormControl(''),
      mailings: this.fb.array([]),
    });

    const patch = {
      name: this.action.name,
      description: this.action.description,
      campaign_id: this.action.campaign_id,
      included_mails: this.action.included_mails,
      campaigns: [],
      mailings: [],
    };

    baseForm.patchValue(patch);
    this.actionForm = baseForm;

    this.action.mailings.forEach((mailing: any) => {
      // console.log('mailing', mailing);
      mailing.required = (mailing.required == 1) ? true : false;
      mailing.opt_in = (mailing.opt_in == 1) ? true : false;
      (this.actionForm.get('mailings') as FormArray).push(this.fb.group(mailing));
    });
  }

  changeOption(event: any): void {
    if (event?.checked) {
      this.disable = false;
    } else {
      this.disable = true;
    }
  }

  getCampaignName(id: any): string {
    return this.campaignName(Number(id));
  }

  updateAction(event): void {
    event.preventDefault();

    const val = this.actionForm.getRawValue();
    val.id = this.action.id;

    this.update.emit(val);
  }

  clone(): void {
    this.enable = true

    this.createActionRes = this.actionService.createActionClone(this.action);

    this.enable = false
  }

  deleteAction(): void {
    this.delete.emit(this.action.id);
  }

  handleChange(evt, opt, j, aid) {
    var target = evt.target;
    if (target.checked) {
    }//evt, opt, j, aid, 
    var optName = 'opt_' + opt;
    console.log(this.actionForm.controls.mailings.value[j].optName = 0);
  }

  openCreateDialog(): void {
    const dialogRef = this.dialog.open(ActionStatisticsComponent, {
      width: '28em',
      data: {
        statistics: this.action,
      }
    });
  }
}
