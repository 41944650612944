import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MailingFormComponent } from "../mailing-form/mailing-form.component";
import { NotifierService } from "angular-notifier";
import { Action } from 'src/app/interfaces/action';

export interface MailingFormData {
  templates: object[];
  actions: object[];
}

@Component({
  selector: 'app-create-mailing-form',
  templateUrl: '../mailing-form/mailing-form.component.html',
  styleUrls: ['../mailing-form/mailing-form.component.scss']
})
export class CreateMailingFormComponent extends MailingFormComponent {

  templates: object[];
  actions: object[];
  sidebar = false;

  constructor(
    public notif: NotifierService,
    public dialogRef?: MatDialogRef<MailingFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data?: MailingFormData,
  ) {
    super(notif);

    this.templates = data.templates;
    this.actions = data.actions;
  }

  onSave(): void {
    if (this.mailingForm.invalid) {
      this.notif.notify('error', 'Angaben sind unvollständig oder ungültig.');
      return;
    }

    this.dialogRef.close({
      name: this.mailingForm.get('name').value,
      date: this.mailingForm.get('date').value + ' ' + this.mailingForm.get('time').value,
      description: this.mailingForm.get('description').value,
      cleverreach_template: this.mailingForm.get('cleverreach_template').value,
      actions: [this.mailingForm.get('actions').value],
    });
  }
}
