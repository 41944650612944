<div class="header">
  <img src="/assets/smg-tech.png" alt="Schmetterling Technology" />
</div>

<div class="signout-page-wrapper">
  <h2>Aktualisieren Sie Ihre Daten</h2>

  <form [formGroup]="userForm">
    <div class="input-group">
      <label for="signoutPageFirstName">Vorname</label>
      <input type="text" id="signoutPageFirstName" formControlName="firstName" />
    </div>

    <div class="input-group">
      <label for="signoutPageLastName">Nachname</label>
      <input type="text" id="signoutPageLastName" formControlName="lastName" />
    </div>

    <div class="input-group">
      <label for="signoutPageEmail">E-Mail-Adresse</label>
      <input type="email" id="signoutPageEmail" formControlName="email" />
    </div>

    <div class="bold">Ich möchte E-Mails zu folgenden Themen erhalten:</div>
    <div class="options-group" formGroupName="categories">
      <div class="group">
        <input type="checkbox" id="signoutPageUpdatesOption" formControlName="novelties" />
        <label for="signoutPageUpdatesOption">Produktneuheiten</label>
      </div>

      <div class="group">
        <input type="checkbox" id="signoutPageDevelopmentsOption" formControlName="developments" />
        <label for="signoutPageDevelopmentsOption">Produkt-Weiterentwicklungen</label>
      </div>

      <div class="group">
        <input type="checkbox" id="signoutPageNewsletterOption" formControlName="newsletter" />
        <label for="signoutPageNewsletterOption">Schmetterling vor 9</label>
      </div>

      <div class="group">
        <input type="checkbox" id="signoutPagePromotionOption" formControlName="promotion" />
        <label for="signoutPagePromotionOption">Promotion</label>
      </div>

      <div class="group">
        <input type="checkbox" id="signoutPageEventsOption" formControlName="eventsAcademy" />
        <label for="signoutPageEventsOption">Events & Akademie</label>
      </div>
    </div>

    <div class="bold">Keines der oben genannten Themen ist für mich von Interesse, ich möchte mich abmelden:</div>
    <div>
      <input type="checkbox" id="signoutPageSignout" (change)="toggleDisable($event)" />
      <label for="signoutPageSignout">Ich möchte keine weiteren E-Mails von Schmetterling erhalten</label>
    </div>

    <button (click)="setData($event)">EINSTELLUNGEN AKTUALISIEREN</button>
  </form>
</div>
