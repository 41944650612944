<div class="content-wrapper master-spacing">
  <h2>Aktionen verwalten</h2>

  <div class="create-action">
    <label class="invisible" for="actionNameNew">Name</label>
    <input type="text" placeholder="Name neuer Aktion" id="actionNameNew" [(ngModel)]="newCampaignName" />
    <div class="create-button">
      <button (click)="saveAction()">Anlegen</button>
    </div>
  </div>

  <div class="action-items-wrapper" *ngIf="isCampaignMapSet()">
    <action-form *ngFor="let action of actions$ | async" [action]="action" [campaignMap]="campaignMap"
      [campaignName]="getCampaignName" [campaignKeys]="getCampaignKeys" (update)="updateAction($event)"
      (delete)="deleteAction($event)">
    </action-form>
  </div>

</div>