import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { MatTableDataSource } from "@angular/material/table";
import { AgencyUser } from "../interfaces/agency-user";
import { MatPaginator, PageEvent } from "@angular/material/paginator";

@Component({
  selector: 'app-b2b-manager',
  templateUrl: './b2b-manager.component.html',
  styleUrls: ['./b2b-manager.component.scss']
})
export class B2bManagerComponent implements OnInit {

  items: object[] = [];
  dataSource: MatTableDataSource<AgencyUser>;
  @ViewChild('clientPaginator') clientPaginator: MatPaginator;
  @ViewChild('serverPaginator') serverPaginator: MatPaginator;
  isServer: boolean;

  agencies: object[] = [];

  columnDefinitions: object[] = [
    { def: 'agency', show: true },
    { def: 'firstName', show: true },
    { def: 'lastName', show: true },
    { def: 'email', show: true },
    { def: 'novelties', show: true },
    { def: 'developments', show: true },
    { def: 'eventsAcademy', show: true },
    { def: 'newsletter', show: true },
    { def: 'promotion', show: true },
  ];

  example: object = {
    firstName: '',
    lastName: '',
    email: '',
    categories: {
      novelties: true,
      developments: true,
      eventsAcademy: true,
      newsletter: true,
      promotion: true,
    }
  };

  totalLength: number = 0;
  length: number = 0;
  agency: number = 0;
  searchValue: string = "";

  constructor(public http: HttpClient) { }

  ngOnInit(): void {
    this.onChangeView();

    this.isServer = true;

    this.http.get<any>('/api/agencies').subscribe(data => {
      this.agencies = data;
    });

   
  }

  log() {
    this.http.post<any>('/api/post-test', { customers: this.items }).subscribe(data => {
      console.log(data);
    });
  }

  doExport() {
    // promisify this, or at least handle the error
    const options: Object = {
      responseType: 'text',
    };
    this.http.post<any>('/api/export', this.items, options).subscribe(data => {
      const blob = new Blob([data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);

      let a = document.createElement('a');
      a.href = url;
      a.download = 'users.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    });
  }

  onChangeView(id: string = '0') {
    let params = new HttpParams();
    if (id === '0') {
      params = params.append('page', '0');
    }

    this.http.get<AgencyUser[]>(`/api/agencies/${id}/users`, { params }).subscribe(data => {
      this.columnDefinitions[0]['show'] = id === '0';

      this.agency = Number(id);
      this.initDataSource(data);
      this.getPageCount();
    });
  }

  doSearchForNameOrEmail() {
    if (!this.searchValue.replace(/\s/g, '').length) {
      return;
    }

    this.http.get<AgencyUser[]>(`/api/agencies/0/users?search=${this.searchValue}`).subscribe(data => {
      this.initDataSource(data);
    });
  }

  doFilter(value: string) {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  onChangePage(event: PageEvent) {
    console.log('change');
    const params = new HttpParams().append('page', String(event.pageIndex));

    this.http.get<AgencyUser[]>(`/api/agencies/0/users`, { params }).subscribe(data => {
      this.initDataSource(data, false);
    });
  }

  getDisplayedColumns() {
    return this.columnDefinitions
      .filter(cd => cd['show'])
      .map(cd => cd['def']);
  }

  private initDataSource(data, paginate: boolean = true) {
    // for exports
    this.items = data;

    this.dataSource = new MatTableDataSource<AgencyUser>(data);

    this.dataSource.filterPredicate = function (data, filter: string): boolean {
      return (data?.firstName || '').toLowerCase().includes(filter)
        || (data?.lastName || '').toLowerCase().includes(filter)
        || `${data?.firstName} ${data?.lastName}`.toLowerCase().includes(filter)
        || (data?.email || '').toLowerCase().includes(filter);
    };

    // this.dataSource.paginator = this.agency === 0 ? this.serverPaginator : this.clientPaginator;

    console.log('is not server: ' + !this.isServer);
    console.log('agency: ' + this.agency);
    if (paginate || (!this.isServer && this.agency === 0)) {
      this.paginate(this.agency === 0);
    }
    // if (paginate) {
    //   console.log(this.agency);
    //   this.dataSource.paginator = this.agency === 0 ? this.serverPaginator : this.clientPaginator;
    // }
  }

  private paginate(server: boolean = true) {
    this.isServer = server;
    this.dataSource.paginator = server ? this.serverPaginator : this.clientPaginator;
    console.log('server: ' + server);
  }

  private getPageCount() {
    const params = new HttpParams().append('getCount', '1');

    this.http.get<any>(`/api/agencies/${this.agency}/users`, { params }).subscribe(data => {
      if (data.hasOwnProperty('count')) {
        if (this.agency === 0) {
          this.totalLength = data['count'];
        }
        this.length = data['count'];
      }
    });
  }
}
