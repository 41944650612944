import { Component, Input, OnInit } from '@angular/core';
import {NavbarService} from "../services/navbar.service";
import {UserService} from "../services/user.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() flag: boolean

  constructor(public nav: NavbarService, private user: UserService) {
  }

  ngOnInit(): void {
    // console.log('HeaderComponent flag', this.flag)

  }

  onLogout() {
    this.user.logout();
    this.nav.hideMenu();
  }
}
