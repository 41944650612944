<div *ngIf="mail === undefined" mat-dialog-title>
  Mail erstellen
</div>

<form [formGroup]="mailForm">
  <div class="mailing-item">
    <div class="item-column">

      <div class="input-wrapper">
        <label for="inputMailDate">Datum/Uhrzeit</label>
        <input type="date" id="inputMailDate" formControlName="date" />
      </div>

      <div class="input-wrapper">
        <label for="inputMailTime" class="invisible"></label>
        <input type="time" id="inputMailTime" formControlName="time" />
      </div>

      <div class="input-wrapper">
        <label for="selectMailTemplate" class="invisible"></label>
        <select id="selectMailTemplate" formControlName="template">
          <option selected disabled>Mailvorlage</option>
          <option *ngFor="let template of templates" [ngValue]="template.id">{{template.name}}</option>
        </select>
      </div>

      <div class="input-wrapper">
        <label for="selectReceiverList" class="invisible"></label>
        <select id="selectReceiverList" formControlName="receiverList">
          <option selected disabled>Empfängerliste</option>
          <option *ngFor="let receiverList of receiverLists" [ngValue]="receiverList.id">{{receiverList.name}}</option>
        </select>
      </div>
      
    </div>
    <div class="item-column">
      <label for="textareaMailDescription" class="invisible"></label>
      <textarea placeholder="Beschreibung" id="textareaMailDescription" formControlName="description"></textarea>
    </div>
  </div>
</form>

<div class="form-controls" *ngIf="mail === undefined; else updateForm">
  <button mat-button color="primary" class="right" (click)="onSave()">Speichern</button>
</div>

<ng-template #updateForm>
  <div class="form-controls">
    <button (click)="exportStatistics()">Export Mailstatistik</button>
    <button (click)="showBounces()">Anzeige Bounces</button>
    <div class="right">
      <button (click)="update()" [disabled]="disableUpdate">Speichern</button>
      <button (click)="doDelete()">Löschen</button>
    </div>
  </div>
</ng-template>