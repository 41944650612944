import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { QueryBuilderModule } from 'angular2-query-builder';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AppRoutingModule } from '../app-routing.module';
import { MaterialModule } from '../material/material.module';
// import { NgSelectModule } from '@ng-select/ng-select';

const arr = [
  BrowserModule,
  AppRoutingModule,
  HttpClientModule,
  BrowserAnimationsModule,
  ReactiveFormsModule,
  MomentDateModule,
  MaterialModule,
  FormsModule,
  QueryBuilderModule,
  NgMultiSelectDropDownModule,
  ClipboardModule,
  // NgSelectModule,
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    arr
  ],
  exports: [
    arr
  ]
})
export class AppCommonModule { }
