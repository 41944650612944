import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from 'rxjs';
import { Action } from '../interfaces/action';
import { NotifierService } from 'angular-notifier';

@Injectable({
  providedIn: 'root'
})
export class ActionService {

  createActionRes: any;

  private _actions = new BehaviorSubject<Action[]>([]);
  private dataStore: { actions: Action[] } = { actions: [] };

  constructor(private http: HttpClient, private notif: NotifierService) { }

  get(): Observable<Action[]> {
    return this._actions.asObservable();
  }

  loadActions(): void {
    this.http.get<Action[]>('/api/actions').subscribe(
      data => {
        this.dataStore.actions = data;
        this._actions.next(Object.assign({}, this.dataStore).actions);
      },
      error => console.log('Could not load actions')
    );
  }

  createAction(name: string): void {
    this.http.post('/api/actions', { name: name }).subscribe(
      data => {
        if (!data.hasOwnProperty('success') || !data['success']) {
          this.notif.notify('error', 'Anlegen fehlgeschlagen');
          return;
        }

        if (data.hasOwnProperty('id')) {
          const action: Action = {
            id: data['id'],
            name,
            description: '',
            campaigns: [],
            mailings: [],
            campaign_id: '',
            included_mails: '',
          };
          this.dataStore.actions.push(action);

          this.notif.notify('success', 'Neue Aktion angelegt');
        }

        if (data.hasOwnProperty('action')) {
          this.dataStore.actions.push(data['action']);
          this._actions.next(Object.assign({}, this.dataStore).actions);
        }

        //this.createActionRes = data
      },
      error => console.log('Could not create action')
    );

    //return this.createActionRes
  }

  updateAction(action: Action): void {
    const id = action.id;
    delete action.id;

    this.http.put(`/api/actions/${id}`, action).subscribe(
      data => {
        if (!data.hasOwnProperty('success') || !data['success']) {
          this.notif.notify('error', 'Speichern fehlgeschlagen');
          return;
        }

        this.dataStore.actions.forEach((a, i) => {
          if (a.id === id) {
            action.id = id;
            this.dataStore.actions[i] = action;
          }
        });

        this.notif.notify('success', 'Aktion erfolgreich aktualisiert');
      },
      error => console.log('Could not update action'),
    );
  }

  createActionClone(actions: any) {
    this.http.post('/api/actions', { name: actions.name + ' copy' }).subscribe(
      data => {
        if (!data.hasOwnProperty('success') || !data['success']) {
          this.notif.notify('error', 'Anlegen fehlgeschlagen');
          return;
        }

        if (data.hasOwnProperty('id')) {
          const action: Action = {
            id: data['id'],
            name: actions.name + ' copy',
            description: '',
            campaigns: [],
            mailings: [],
            campaign_id: '',
            included_mails: '',
          };
          this.dataStore.actions.push(action);

          this.notif.notify('success', 'Neue Aktion angelegt');
        }

        if (data.hasOwnProperty('action')) {
          this.dataStore.actions.push(data['action']);
          this._actions.next(Object.assign({}, this.dataStore).actions);
        }

        actions.id = data['id']
        actions.name = actions.name + ' copy'

        this.updateAction(actions)

      },
      error => console.log('Could not create action')
    );
  }

  deleteAction(id: string): void {
    this.http.delete(`/api/actions/${id}`).subscribe(
      data => {
        if (!data.hasOwnProperty('success') || !data['success']) {
          this.notif.notify('error', 'Löschen fehlgeschlagen');
          return;
        }

        this.notif.notify('success', 'Aktion erfolgreich gelöscht');

        this.dataStore.actions.forEach((a, i) => {
          if (a.id === id) {
            this.dataStore.actions.splice(i, 1);
          }

          this._actions.next(Object.assign({}, this.dataStore).actions);
        });
      },
      error => {
        this.notif.notify('error', 'Löschen fehlgeschlagen');
        console.log('Could not delete action');
      }
    );
  }
}
