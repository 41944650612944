import { Component } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { routerAnimation } from './animations/animations';
import { NavigationService } from './services/navigation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    routerAnimation
  ]
})
export class AppComponent {
  title = 'campaign-master-gui';
  flag: boolean = true

  constructor(
    private navigationService: NavigationService,
    private router: Router
  ) {
    router.events.subscribe((val: any) => {
      // console.log('val.url', val.url)
      if( val.url !== undefined ) {

        const arr = val.url.split('/')
        // console.log('arr[1]', arr[2])
        // if( val.url === '/newsletter' ) {
        if( arr[2] === 'newsletter' ) {
          this.flag = false
        } 
        // else {
        //   this.flag = true
        // }
      }
      
      
  });
  }

  getRouteAnimation(outlet: RouterOutlet) {
    return this.navigationService.animationValue;
  }
}
