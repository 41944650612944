import { NgModule } from '@angular/core';

import { MatTableModule } from "@angular/material/table";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatIconModule } from "@angular/material/icon";
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from "@angular/material/list";
import { MatAutocompleteModule } from '@angular/material/autocomplete';

const arr = [
  MatTableModule,
  MatFormFieldModule,
  MatInputModule,
  MatDatepickerModule,
  MatButtonModule,
  MatDialogModule,
  MatSlideToggleModule,
  MatPaginatorModule,
  MatIconModule,
  MatChipsModule,
  MatListModule,
  MatAutocompleteModule,
]

@NgModule({
  declarations: [],
  imports: [
    arr
  ],
  exports: [
    arr
  ]
})
export class MaterialModule { }
