<!-- <nav class="app-nav" *ngIf="nav.visible"> -->
<nav class="app-nav" *ngIf="flag">
  <div class="app-nav-title">Campaign Master</div>
  <ul *ngIf="nav.visibleMenu">
    <li class="app-nav-item" routerLinkActive="selected"><a routerLink="/campaign-list">Kampagnen</a></li>
    <li class="app-nav-item" routerLinkActive="selected"><a routerLink="/action-list">Aktionen</a></li>
    <li class="app-nav-item" routerLinkActive="selected"><a routerLink="/mailing-list">Mailings</a></li>
    <li class="app-nav-item" routerLinkActive="selected"><a routerLink="/b2b-manager">B2B-Manager</a></li>
    <li class="app-nav-item" routerLinkActive="selected"><a routerLink="/b2b-mail">B2B Mail versenden</a></li>
    <!-- <li class="app-nav-item"><a routerLink="/test">Test!!</a></li> -->
    <!-- <li class="app-nav-item right"><a (click)="onLogout()">Abmelden</a></li> -->
  </ul>
</nav>