<div class="content-wrapper master-spacing">
  <div class="mail-forms-header">
    <h3>Angelegte B2B Mailings</h3>

    <div class="right">
      <button mat-raised-button color="primary" (click)="openCreateDialog()">Neues Mailing erstellen</button>

      <mat-form-field>
        <mat-label>Monat</mat-label>
        <input matInput [matDatepicker]="dp" [formControl]="date" />
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp startView="year" (monthSelected)="onChosenMonth($event, dp)"
          panelClass="example-month-picker">
        </mat-datepicker>
      </mat-form-field>
    </div>

  </div>

  <div class="forms-wrapper">
    <ng-container *ngFor="let mail of mails$ | async">
      <mail-form [mail]="mail" [templates]="templates" [receiverLists]="receiverLists" (updateMail)="onUpdate($event)"
        (deleteMail)="onDelete($event)"></mail-form>
    </ng-container>
  </div>
</div>