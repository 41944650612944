import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { NewsLetter } from '../interfaces/newsletter';
import { InitService } from '../services/init.service';
import { UnsubscribeService } from '../services/unsubscribe.service';

@Component({
  selector: 'app-newsletter-form',
  templateUrl: './newsletter-form.component.html',
  styleUrls: ['./newsletter-form.component.scss']
})
export class NewsletterFormComponent implements OnInit {

  nsForm: FormGroup;
  status: any;

  constructor(
    private unsubscribeService: UnsubscribeService,
    private notif: NotifierService
  ) { }

  ngOnInit(): void {
    this.nsForm = new FormGroup({
      vomame: new FormControl(),
      nachname: new FormControl(),
      emailadresse: new FormControl('', [Validators.email]),

      produktneuheiten: new FormControl(),
      produkt: new FormControl(),
      schmetterlingvor: new FormControl(),
      promotion: new FormControl(),
      events: new FormControl(),

      notes: new FormControl(),
    });
  }

  onSave() {
    if (this.nsForm.invalid) {
      this.notif.notify('error', 'enter valid emailadresse')
      return
    }

    let vomame = this.nsForm.get('vomame').value
    if (!vomame) {
      this.notif.notify('error', 'enter vomame')
      return
    }

    let nachname = this.nsForm.get('nachname').value
    if (!nachname) {
      this.notif.notify('error', 'enter nachname')
      return
    }

    let emailadresse = this.nsForm.get('emailadresse').value
    if (!emailadresse) {
      this.notif.notify('error', 'enter valid emailadresse')
      return
    }

    let produktneuheiten = this.nsForm.get('produktneuheiten').value
    let produkt = this.nsForm.get('produkt').value
    let schmetterlingvor = this.nsForm.get('schmetterlingvor').value
    let promotion = this.nsForm.get('promotion').value
    let events = this.nsForm.get('events').value
    let notes = this.nsForm.get('notes').value

    if (produktneuheiten || produkt || schmetterlingvor || promotion || events || notes) {
    } else {
      this.notif.notify('error', 'choose from topics or unsubscribe if not interested')
      return
    }

    this.unsubscribeService.createUnsubscribe(this.nsForm.value)

    this.nsForm.reset()
  }

  checkInput(event, type) {
    let val = event.target.value
    if (type === 'n') {
      this.nsForm.get('produktneuheiten').setValue(false)
      this.nsForm.get('produkt').setValue(false)
      this.nsForm.get('schmetterlingvor').setValue(false)
      this.nsForm.get('promotion').setValue(false)
      this.nsForm.get('events').setValue(false)
    } else {
      this.nsForm.get('notes').setValue(false)
    }

  }

}
