<div class="content-wrapper master-spacing">
  <div class="header">
    <h2>Mailings verwalten</h2>

    <div class="flex-form">
      <div class="calendar">
        <button class="calendar-control" (click)="onArrowClick(-1)">
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <mat-form-field>
          <mat-label>Monat</mat-label>
          <input matInput [matDatepicker]="dp" [formControl]="date" />
          <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
          <mat-datepicker #dp startView="year" (monthSelected)="onChosenMonth($event, dp)"
            panelClass="example-month-picker">
          </mat-datepicker>
        </mat-form-field>
        <button class="calendar-control" (click)="onArrowClick(1)">
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
      </div>

      <button mat-raised-button (click)="openCreateDialog()" class="btn-new-mailing">Mailing erstellen</button>

      <!-- <button mat-raised-button title="Entwürfe auf Hauptaccount" class="btn-drafts" (click)="openDrafts()"
        *ngIf="(drafts$ | async)?.length > 0">
        <mat-icon>drafts</mat-icon>
      </button> -->
    </div>
  </div>

  <div class="mailing-items-wrapper">
    <mailing-form *ngFor="let mailing of mailings$ | async" [mailing]="mailing" [templates]="drafts"
      [actions]="actions$ | async" [agencies]="agencies$" [actionslist]="actionslist$" [campaignlist]="campaignlist$"
      (saveMail)="onSave($event)" (updateMail)="onUpdate($event)" (deleteMail)="onDelete($event)"></mailing-form>
    <div *ngIf="(mailings$ | async).length === 0" class="none-placeholder-msg">Keine Mailings für diesen Monat</div>
  </div>
</div>