<h2 mat-dialog-title>Ausgewählte Büros</h2>

<mat-dialog-content>
  <mat-table [dataSource]="agencies">
    <ng-container matColumnDef="agencyId">
      <mat-header-cell *matHeaderCellDef class="mat-column-agency">Agtr-Nummer</mat-header-cell>
      <mat-cell *matCellDef="let item" class="mat-column-agency">{{item?.agencyId}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="clientId">
      <mat-header-cell *matHeaderCellDef class="mat-column-cr-number">CR-Nummer</mat-header-cell>
      <mat-cell *matCellDef="let item" class="mat-column-cr-number">{{item?.clientId}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef class="mat-column-name">Name</mat-header-cell>
      <mat-cell *matCellDef="let item" class="mat-column-name">{{item?.name}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="module">
      <mat-header-cell *matHeaderCellDef class="mat-column-module">Modul(e)</mat-header-cell>
      <mat-cell *matCellDef="let item" class="mat-column-module">
        {{item?.has_go4it === '1' ? 'GO4IT' : ''}} {{item?.has_apm === '1' ? 'APM' : ''}}
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</mat-dialog-content>
