<form class="flex-form" [formGroup]="campaignForm">
  <div class="form-column">
    <label class="invisible" for="campaignName"></label>
    <input type="text" id="campaignName" placeholder="Name der Kampagne" formControlName="name" />
    <label>ID: #{{campaign.id}}</label>
    <div class="form-column buttons-wrapper">
      <button (click)="fileInput.click()">
        <mat-icon>add_a_photo</mat-icon> <span>Branding</span>
        <input #fileInput type="file" (change)="readURL($event)" id="campaignLogo" style="display: none;"
          accept="image/*" />
      </button>

      <button [disabled]="disableUpdateBtn" (click)="onSave()">Speichern</button>
    </div>
  </div>
  <div class="form-column">
    <div class="branding-wrapper">
      <img *ngIf="imageSrc || campaign.image_src" [src]="imageSrc || campaign.image_src"
        [alt]="campaign.name + ' logo'" />
    </div>
  </div>
  <div class="form-column agencies-list">
    <label>Agenturen</label>
    <ng-multiselect-dropdown [placeholder]="'Agenturen auswählen'" [settings]="dropdownSettings" [data]="agencies"
      formControlName="agencies" class="custom-width"></ng-multiselect-dropdown>

    <div class="buttons-row">
      <button title="Alle GO4IT Büros" (click)="selectAgenciesOfModule('go4it')">GO4IT</button>
      <button title="Alle APM Büros" (click)="selectAgenciesOfModule('apm')">APM</button>
      <button title="Alle Zusatzmailing Büros" (click)="selectAgenciesOfModule('zusatz')">Zusatzmailing</button>
    </div>

    <div class="buttons-row">
      <button title="Liste ausgewählter Büros" (click)="showSelectedAgencies($event)">
        <mat-icon>list</mat-icon>
      </button>

      <button title="Logo-URL einblenden" (click)="toggleDisplayURL()" *ngIf="campaign.image_src">
        <mat-icon>image</mat-icon>
      </button>

      <button title="Logo-URL kopieren" [cdkCopyToClipboard]="campaign.image_src" (click)="notifyCopied()"
        *ngIf="campaign.image_src">
        <mat-icon>file_copy</mat-icon>
      </button>

      <button title="Kampagne löschen" (click)="deleteCampaign()" class="delete-btn">
        <mat-icon>delete</mat-icon>
      </button>

      <mat-slide-toggle [checked]="campaign.status" formControlName="status"></mat-slide-toggle>
    </div>

    <div class="url-container">
      <textarea [value]="campaign.image_src" *ngIf="displayURL"></textarea>
    </div>
  </div>
</form>