import { Component, OnInit } from '@angular/core';

export interface PeriodicElement {
  agencyNumber: number;
  agencyName: string;
  bookedMailings: number;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { agencyNumber: 1, agencyName: 'Hydrogen', bookedMailings: 33 },
  { agencyNumber: 2, agencyName: 'Helium', bookedMailings: 33 },
  { agencyNumber: 3, agencyName: 'Lithium', bookedMailings: 33 },
];

@Component({
  selector: 'app-action-statistics',
  templateUrl: './action-statistics.component.html',
  styleUrls: ['./action-statistics.component.scss']
})
export class ActionStatisticsComponent implements OnInit {

  statistics: object[];
  displayedColumns: string[] = ['agencyNumber', 'agencyName', 'bookedMailings'];
  dataSource = ELEMENT_DATA;

  constructor() { }

  ngOnInit(): void {

  }

}