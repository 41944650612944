import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Campaign } from '../../interfaces/campaign';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Agency } from '../../interfaces/agency';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MatDialog } from '@angular/material/dialog';
import { AgencyListComponent } from '../../agency-list/agency-list.component';

@Component({
  selector: 'campaign-form',
  templateUrl: './campaign-form.component.html',
  styleUrls: ['./campaign-form.component.scss']
})
export class CampaignFormComponent implements OnInit {

  campaignForm: FormGroup;

  @Input() campaign?: Campaign;
  @Input() agencies?: Agency[] = [];
  @Input() notif: (str: string) => void; // send message to notify user from parent
  @Output() onUpdate: EventEmitter<Campaign> = new EventEmitter<Campaign>();
  @Output() onDelete = new EventEmitter<string>();

  displayURL = false;
  dropdownSettings: IDropdownSettings;

  agencyMatches = this.agencies;
  imageSrc: string | ArrayBuffer;

  disableUpdateBtn: boolean = true;
  reference: object = {};

  isChecked = true;

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
    
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'agencyId',
      textField: 'fullName',
      enableCheckAll: true,
      selectAllText: 'Alle auswählen',
      unSelectAllText: 'Alle abwählen',
      searchPlaceholderText: 'Suche',
      allowSearchFilter: true,
      itemsShowLimit: 0,
    };

    this.campaignForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      image_src: new FormControl(''),
      logo: new FormControl(''),
      agencies: new FormControl(''),
      status: new FormControl(''),
    });

    if (this.campaign !== undefined) {
      const ref = {
        name: this.campaign.name || '',
        image_src: this.campaign.image_src || '',
        logo: this.campaign.logo || '',
        agencies: this.campaign.agencies || [],
        status: (this.campaign.status == 1) ? 1 : 0,
      };

      this.campaignForm.patchValue(ref);
      this.createReference(ref);

      this.campaignForm.valueChanges.subscribe(changes => this.isFormDirty(changes));
    }
  }

  isSelected(id: any): boolean {
    return this.campaign.agencies.includes(id);
  }

  onKey(value: string): void {
    this.agencyMatches = this.search(value);
  }

  search(value: string): object[] {
    const filter = value.toLowerCase();
    return this.agencies.filter(
      option => option.name.toLowerCase().includes(filter) || String(option.agencyId).startsWith(filter)
    );
  }

  readURL(event): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => this.imageSrc = reader.result;
      this.campaignForm.patchValue({
        logo: file,
      });

      reader.readAsDataURL(file);
    }
  }

  onSave(): void {
    this.onUpdate.emit({
      id: this.campaign.id,
      name: this.campaignForm.get('name').value,
      logo: this.campaignForm.get('logo').value,
      agencies: this.campaignForm.get('agencies').value.map((a, i) => typeof a === 'string' ? a : a['agencyId']),
      status: (this.campaignForm.get('status').value) ? 1 : 0,
    });
  }

  deleteCampaign(): void {
    this.onDelete.emit(this.campaign.id);
  }

  showSelectedAgencies(e): void {
    e.preventDefault();

    if (this.campaignForm.get('agencies').value.length === 0) {
      this.notif('Liste ist leer');
      return;
    }

    const agencies = [];
    this.campaignForm.get('agencies').value.forEach((a, i) => {
      let obj;
      switch (typeof a) {
        case 'string':
          obj = this.agencies.find(agency => String(agency.agencyId) === a);
          break;

        case 'object':
          if (a.agencyId === a.fullName || !a.clientId || !a.name) {
            obj = this.agencies.find(agency => agency.agencyId === a.agencyId);
          } else {
            obj = a;
          }
          break;
      }

      agencies.push(obj);
    });

    this.dialog.open(AgencyListComponent, {
      width: '50em',
      maxHeight: '50em',
      data: {
        agencies,
      },
    });
  }

  notifyCopied(): void {
    this.notif('Logo-URL kopiert');
  }

  toggleDisplayURL(): void {
    this.displayURL = !this.displayURL;
  }

  selectAgenciesOfModule(module: string = ''): void {
    module = module.toLowerCase();

    const prev = this.campaignForm.get('agencies').value.map((p) => {
      if (typeof p === 'string') return p;
      if (typeof p === 'object') return p.agencyId;
    });

    // find all thingies with that module
    const ag = this.agencies.filter((a, i) => {
      return ((module === 'go4it' && a.has_go4it === '1') || (module === 'apm' && a.has_apm === '1') || (module === 'zusatz' && a.has_zusatz === '1')) && !prev.includes(a.agencyId)
        ? a
        : null;
    });

    prev.length = 0
    prev.push(...ag);

    this.campaignForm.patchValue({ agencies: prev });
    this.notif('Alle ' + module + ' Büros');
  }

  private createReference(obj: any): void {
    this.reference = Object.assign({}, obj);
  }

  private isFormDirty(currentValue): void {
    // console.log(currentValue);

    for (const field of ['name', 'logo', 'image_src', 'agencies', 'status']) {
      if (Array.isArray(currentValue[field]) && currentValue[field].sort().toString() !== this.reference[field].sort().toString()) {
        this.disableUpdateBtn = false;
        return;
      } else if (!Array.isArray(currentValue[field]) && currentValue[field] !== this.reference[field]) {
        this.disableUpdateBtn = false;
        return;
      }
    }

    this.disableUpdateBtn = true;
  }
}
