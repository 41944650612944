<query-builder [(ngModel)]="query">
  <ng-container *queryButtonGroup="let ruleset; let addRule=addRule; let addRuleSet=addRuleSet; let removeRuleSet=removeRuleSet">
    <button type="button" mat-button (click)="addRule()" #btnRS>+ Rule</button>
    <button #btnARS type="button" mat-button (click)="addRuleSet()">+ Ruleset</button>
    <button type="button" mat-button (click)="removeRuleSet()" #btnRRS>- Ruleset</button>
  </ng-container>
</query-builder>

<div class="selector-wrapper">
  <div class="collapse hyper-selector">
    <mat-form-field class="selected-criteria-wrapper">
  <!--    <mat-label>Chips</mat-label>-->
      <mat-chip-list aria-label="Things">
        <mat-chip *ngFor="let criterion of selectedCriteria"
          [removable]="true"
          [selectable]="false"
          (removed)="onRemove(criterion)"
          class="travel-custom-chip"
        >
          {{ criterion }}
          <span matChipRemove class="chip-icon">
            <mat-icon>cancel</mat-icon>
          </span>
        </mat-chip>
      </mat-chip-list>
    </mat-form-field>

    <div class="criteria-wrapper">
<!--      <div class="selector-column" (click)="onCriterionSelect($event.target.value)">-->
      <div class="selector-column">
        <div class="selector-group">
          <div class="group-title">Reisebüros</div>
          <ng-multiselect-dropdown
            [placeholder]="'- Reisebüros auswählen -'"
            [(ngModel)]="selectedAgencies"
            [settings]="dropdownSettings"
            [data]="agenciesList"
          ></ng-multiselect-dropdown>
        </div>

        <div class="selector-group">
          <div class="group-title">Land</div>

          <ng-multiselect-dropdown
            [placeholder]="'- Länder auswählen -'"
            [settings]="dropdownSettings"
            [data]="countries"
          ></ng-multiselect-dropdown>

          <div class="item-group">
            <div class="selector-item">
              <input type="checkbox" id="checkboxDACH" name="country.dach" (click)="onSelectDach($event.target.checked)" />
              <label for="checkboxDACH">DACH</label>
            </div>
            <div class="selector-item">
              <input type="checkbox" id="checkboxBenelux" name="country.benelux" />
              <label for="checkboxBenelux">Benelux</label>
            </div>
            <div class="selector-item">
              <input type="checkbox" id="checkboxEnglish" name="country.english_speaking" />
              <label for="checkboxEnglish">Englischsprachige</label>
            </div>
          </div>
        </div>
      </div>

      <div class="selector-column">
        <!-- SMG Office -->
        <div class="selector-group">
          <div class="group-title">SMG Office</div>

          <div class="item-group">
            <div class="selector-item">
              <input type="checkbox" id="checkboxConfidentialAndEmployees" />
              <label for="checkboxConfidentialAndEmployees">Vertraulich + Mitarbeiter</label>
            </div>
            <div class="selector-item">
              <input type="checkbox" id="checkboxConfidential" value="" />
              <label for="checkboxConfidential">Vertraulich</label>
            </div>
            <div class="selector-item">
              <input type="checkbox" id="checkboxEmployees" value="" />
              <label for="checkboxEmployees">Mitarbeiter</label>
            </div>
            <div class="selector-item">
              <input type="checkbox" id="checkboxEmail1" />
              <label for="checkboxEmail1">Email1</label>
            </div>
            <div class="selector-item">
              <input type="checkbox" id="checkboxChefmail" />
              <label for="checkboxChefmail">Chef-Mail</label>
            </div>
          </div>
        </div>

        <div class="selector-group">
          <div class="group-title">Kooperation</div>

          <ng-multiselect-dropdown
            [placeholder]="'- Kette/Koop auswählen -'"
            [settings]="dropdownSettings"
            [data]=""
          ></ng-multiselect-dropdown>

          <ng-multiselect-dropdown
            [placeholder]="'- Zusatzorg. auswählen -'"
            [settings]="dropdownSettings"
            [data]=""
          ></ng-multiselect-dropdown>

          <div class="item-group">
            <div class="selector-item">
              <input type="checkbox" id="checkboxQTA" />
              <label for="checkboxQTA">QTA</label>
            </div>
            <div class="selector-item">
              <input type="checkbox" id="checkboxQTAwoSMG" />
              <label for="checkboxQTAwoSMG">QTA ohne SMG</label>
            </div>
            <div class="selector-item">
              <input type="checkbox" id="checkboxRahmenvertrag" />
              <label for="checkboxRahmenvertrag">Technikpaket Rahmenvertrag</label>
            </div>
            <div class="selector-item">
              <input type="checkbox" id="checkboxOfficialSMG" />
              <label for="checkboxOfficialSMG">Offizielle SMG Büros</label>
            </div>
            <div class="selector-item">
              <input type="checkbox" id="checkboxAllSMGAgencies" />
              <label for="checkboxAllSMGAgencies">Alle SMG Büros</label>
            </div>
          </div>
        </div>
      </div>

      <div class="selector-column">
        <div class="selector-group">
          <div class="group-title">Produkt</div>

          <ng-multiselect-dropdown
            [placeholder]="'- CRS auswählen -'"
            [settings]="dropdownSettings"
            [data]=""
          ></ng-multiselect-dropdown>

          <ng-multiselect-dropdown
            [placeholder]="'- Module auswählen -'"
            [settings]="dropdownSettings"
            [data]=""
          ></ng-multiselect-dropdown>

          <ng-multiselect-dropdown
            [placeholder]="'- Eigenschaften auswählen -'"
            [settings]="dropdownSettings"
            [data]=""
          ></ng-multiselect-dropdown>

          <ng-multiselect-dropdown
            [placeholder]="'- VA eigene Agentur -'"
            [settings]="dropdownSettings"
            [data]=""
          ></ng-multiselect-dropdown>

          <ng-multiselect-dropdown
            [placeholder]="'- VA Fremdagentur -'"
            [settings]="dropdownSettings"
            [data]=""
          ></ng-multiselect-dropdown>

          <div class="item-group">
            <div class="selector-item">
              <input type="checkbox" id="checkboxArgus" />
              <label for="checkboxArgus">ARGUS</label>
            </div>
            <div class="selector-item">
              <input type="checkbox" id="checkboxTravelAgentCard" />
              <label for="checkboxTravelAgentCard">TA-Card</label>
            </div>
            <div class="selector-item">
              <input type="checkbox" id="checkboxCollectiveContract" />
              <label for="checkboxCollectiveContract">Kollektivvertrag</label>
            </div>
            <div class="selector-item">
              <input type="checkbox" id="checkboxTopPartner" />
              <label for="checkboxTopPartner">TOP-Partner</label>
            </div>
            <div class="selector-item">
              <input type="checkbox" id="checkboxBrandPartner" />
              <label for="checkboxBrandPartner">Markenpartner</label>
            </div>
            <div class="selector-item">
              <input type="checkbox" id="checkboxZubucher" />
              <label for="checkboxZubucher">Zubucher</label>
            </div>
            <!-- Veranstalter eigene Agentur -->
            <!-- Veranstalter Fremdagentur -->
            <div class="selector-item">
              <input type="checkbox" id="checkboxPartisanen" />
              <label for="checkboxPartisanen">Partisanen</label>
            </div>
            <div class="selector-item">
              <input type="checkbox" id="checkboxRBCNL" />
              <label for="checkboxRBCNL">RBCNL</label>
            </div>
            <div class="selector-item">
              <input type="checkbox" id="checkboxTechnikpaket" />
              <label for="checkboxTechnikpaket">Technikpaket</label>
            </div>
            <div class="selector-item">
              <input type="checkbox" id="checkboxTechnikkunde" />
              <label for="checkboxTechnikkunde">Technik-Kunde ohne TP</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="selector-controls">
    <button>UND</button>
    <button>ODER</button>
    <button>Regel</button>
    <button>Regelsatz</button>
    <button>Suche</button>
  </div>
</div>
