import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { B2bManagerComponent } from './b2b-manager/b2b-manager.component';
import { B2bMailComponent } from './b2b-mail/b2b-mail.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { CampaignListComponent } from './campaign-list/campaign-list.component';
import { ActionListComponent } from './action-list/action-list.component';
import { MailingListComponent } from './mailing-list/mailing-list.component';
import { SignoutPageComponent } from './signout-page/signout-page.component';


// todo THIS IS A TEST REMOVE ME LATER
import { HyperSelectorComponent } from './b2b-manager/hyper-selector/hyper-selector.component';
import { NewsletterFormComponent } from './newsletter-form/newsletter-form.component';

const routes: Routes = [
  { path: 'campaign-list', component: CampaignListComponent, data: { num: 1 } },
  { path: 'action-list', component: ActionListComponent, data: { num: 2 } },
  { path: 'mailing-list', component: MailingListComponent, data: { num: 1 } },
  { path: 'b2b-manager', component: B2bManagerComponent, data: { num: 2 } },
  { path: 'b2b-mail', component: B2bMailComponent, data: { num: 1 } },
  { path: 'login', component: LoginFormComponent, data: { num: 2 } },
  { path: 'test', component: HyperSelectorComponent, data: { num: 1 } },

  { path: 'newsletter-signout', component: SignoutPageComponent, data: { num: 2 } },
  { path: ':id/newsletter', component: NewsletterFormComponent, data: { num: 2 } },

  // { path: 'test', component: HyperSelectorComponent },

  // { path: '404', component: '' },
  { path: '**', redirectTo: 'campaign-list', data: { num: 1 } }, // todo: change to login later
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
