import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Mail } from "../interfaces/mail";

@Injectable({
  providedIn: 'root'
})
export class B2bMailsService {

  private _mails = new BehaviorSubject<Mail[]>([]);
  private dataStore: { mails: Mail[] } = { mails: [] };

  constructor(private http: HttpClient) { }

  get() {
    return this._mails.asObservable();
  }

  loadMails(month: number = 0, year: number = 0) {
    let url: string = '/api/b2b/mails';
    if (month && year) {
      url += `?month=${month}&year=${year}`;
    }

    this.http.get<Mail[]>(url).subscribe(
      response => {
        this.dataStore.mails = response;
        this._mails.next(Object.assign({}, this.dataStore).mails);
      },
      error => console.log('Could not load mails')
    );
  }

  createMail(mail: object) {
    this.http.post('/api/b2b/mails', { mail }).subscribe(
      response => {
        if (response.hasOwnProperty('mail')) {
          this.dataStore.mails.push(response['mail']);
          this._mails.next(Object.assign({}, this.dataStore).mails);
        }
      },
      error => console.log('Could not create mail')
    );
  }

  updateMail(mail: object) {
    this.http.put(`/api/b2b/mails/${mail['id']}`, { mail }).subscribe(
      response => {
        if (!response.hasOwnProperty('mail')) return;

        this.dataStore.mails.forEach((m, i) => {
          if (m['id'] === response['mail']['id']) {
            this.dataStore.mails[i] = response['mail'];
          }
        });
      },
      error => console.log('Could not update mail')
    );
  }

  deleteMail(mailId: any) {
    this.http.delete(`/api/b2b/mails/${mailId}`).subscribe(
      response => {
        if (!response['success']) return;

        this.dataStore.mails.forEach((m, i) => {
          if (m['id'] === mailId) {
            this.dataStore.mails.splice(i, 1);
          }

          this._mails.next(Object.assign({}, this.dataStore).mails);
        });
      }
    );
  }
}
