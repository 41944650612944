import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MailFormComponent } from "../mail-form/mail-form.component";
import { NotifierService } from "angular-notifier";

export interface MailFormData {
  templates: object[];
  receiverLists: object[];
}

@Component({
  selector: 'create-mail-form',
  templateUrl: './../mail-form/mail-form.component.html',
  styleUrls: ['./../mail-form/mail-form.component.scss']
})
export class CreateMailFormComponent extends MailFormComponent {

  templates: object[];
  receiverLists: object[];

  constructor(
    public notif: NotifierService,
    public dialogRef?: MatDialogRef<MailFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data?: MailFormData,
  ) {
    super(notif);

    this.templates = data.templates;
    this.receiverLists = data.receiverLists;
  }

  onSave() {
    if (this.mailForm.invalid) {
      this.notif.notify('error', 'Eingaben sind unvollständig oder ungültig.');
      return;
    }

    this.dialogRef.close({
      date: this.mailForm.get('date').value + ' ' + this.mailForm.get('time').value,
      description: this.mailForm.get('description').value,
      template: this.mailForm.get('template').value,
      receiverList: this.mailForm.get('receiverList').value,
    });
  }
}
