import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CampaignFormComponent } from '../campaign-list/campaign-form/campaign-form.component';
import { Agency } from '../interfaces/agency';

export interface CampaignFormData {
  agencies: Agency[];
}

@Component({
  selector: 'app-agency-list',
  templateUrl: './agency-list.component.html',
  styleUrls: ['./agency-list.component.scss']
})
export class AgencyListComponent implements OnInit {

  agencies: Agency[];

  displayedColumns = [
    'agencyId',
    'clientId',
    'name',
    'module',
  ];

  constructor(
    public dialogRef?: MatDialogRef<CampaignFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data?: CampaignFormData
  ) {
    this.agencies = data.agencies;
  }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close();
  }
}
