<div class="content-wrapper master-spacing">
  <h2>Kampagnen verwalten</h2>

  <div class="create-campaign">
    <label class="invisible" for="campaignNameNew">Name</label>
    <input type="text" placeholder="Name neuer Kampagne" id="campaignNameNew" [(ngModel)]="name" />
    <div class="create-button">
      <button (click)="create()">Anlegen</button>
    </div>
    <div class="create-button">
      <button style="width: 130px;" (click)="csvFileInput.click()">
        <span>List Import</span>
        <input #csvFileInput type="file" name="Upload CSV" (change)="uploadListener($event)" style="display: none;" accept=".csv" />
      </button>
    </div>
  </div>

  <div class="campaign-items-wrapper">
    <campaign-form *ngFor="let campaign of campaigns$ | async" [campaign]="campaign" [agencies]="agencies$ | async"
      [notif]="childNotif" (onUpdate)="update($event)" (onDelete)="delete($event)"></campaign-form>
    <div *ngIf="(campaigns$ | async).length === 0" class="none-placeholder-msg">Keine Kampagnen vorhanden</div>
  </div>
</div>