<div class="component-wrapper">
  <form [formGroup]="credentials">
    <label for="username" class="invisible">Benutzername</label>
    <input type="text" id="username" formControlName="username" placeholder="Benutzername" required />

    <label for="password" class="invisible">Passwort</label>
    <input type="password" id="password" formControlName="password" placeholder="Passwort" required />

    <button (click)="onLogin()">Einloggen</button>
  </form>
</div>
