import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Agency } from '../interfaces/agency';
import { Draft } from '../interfaces/draft';

@Injectable({
  providedIn: 'root'
})
export class InitService {

  private _agencies = new BehaviorSubject<Agency[]>([]);
  private drafts = new BehaviorSubject<Draft[]>([]);
  private storage: {
    agencies: Agency[],
    drafts: Draft[]
  } = { agencies: [], drafts: [] };

  constructor(
    private http: HttpClient
  ) { }

  getAgencies(): Observable<Agency[]> {
    return this._agencies.asObservable();
  }

  loadAgencies(): void {
    this.http.get<Agency[]>('/api/init/agencies').subscribe(
      data => {
        for (const agency of data) {
          agency.fullName = String(agency.agencyId) + ' - ' + agency.name + ' (' + agency?.modulnummer + ')';
        }

        this.storage.agencies = data;
        this._agencies.next(Object.assign({}, this.storage).agencies);
      },
      error => console.log('Could not initialize agencies')
    );
  }

  getDrafts(): Observable<Draft[]> {
    return this.drafts.asObservable();
  }

  loadDrafts(): void {
    this.http.get<Draft[]>('/api/init/drafts').subscribe(
      data => {
        this.storage.drafts = data;
        this.drafts.next(Object.assign({}, this.storage).drafts);
      },
      error => console.log('Could not initialize drafts')
    );
  }
}
