import {Component, Inject, Input, OnInit} from '@angular/core';
import {Draft} from '../interfaces/draft';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MailingListComponent} from '../mailing-list/mailing-list.component';
import {MAT_DATE_FORMATS} from '@angular/material/core';
import {NotifierService} from 'angular-notifier';
// import {Moment} from 'moment';
import * as moment from 'moment';
import {Mailing} from '../interfaces/mailing';
import {MailingService} from '../services/mailing.service';

export interface MailingListData {
  drafts: Draft[];
}

const COMP_DATE_FORMAT = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
  }
};

@Component({
  selector: 'app-drafts-list',
  templateUrl: './drafts-list.component.html',
  styleUrls: ['./drafts-list.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: COMP_DATE_FORMAT, },
  ],
})
export class DraftsListComponent implements OnInit {

  drafts: Draft[];

  @Input() saveMailing: (mailing: Mailing) => boolean;

  displayedColumns = [
    'name',
    'subject',
    'last_changed',
    'send_on',
    'send'
  ];

  constructor(
    public notif: NotifierService,
    public mailings: MailingService,
    public dialogRef?: MatDialogRef<MailingListComponent>,
    @Inject(MAT_DIALOG_DATA) public data?: MailingListData,
  ) {
    this.drafts = data.drafts;
  }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close();
  }

  publish(id: string): void {
    const index = this.drafts.findIndex((d) => {
      return d.id === id;
    });

    const draft = this.drafts[index];
    draft.send_on = (document.getElementById('picker-' + id) as HTMLInputElement).value;

    const date = moment(draft.send_on + ' 00:00:00', 'DD.MM.YYYY HH:mm:ss');

    if (!draft.send_on || !date.isValid()) {
      this.notif.notify('warning', 'Bitte Versanddatum eingeben');
      return;
    }

    this.mailings.createMailing({
      cleverreach_id: draft.id,
      date: date.format('YYYY-MM-DD HH:mm:ss'),
      name: draft.name,
      description: draft.subject,
    });
  }

  convertUnix(unix: string) {
    return moment.unix(Number(unix)).format('DD.MM.YYYY HH:mm');
  }
}
