import { Component, OnInit } from '@angular/core';
import { Action } from '../interfaces/action';
import { Observable } from 'rxjs';
import { ActionService } from '../services/action.service';
import { CampaignService } from '../services/campaign.service';

@Component({
  selector: 'app-action-list',
  templateUrl: './action-list.component.html',
  styleUrls: ['./action-list.component.scss']
})
export class ActionListComponent implements OnInit {

  campaignMap: any = [];
  actions: Action[] = [];
  actions$: Observable<Action[]>;

  newCampaignName: string;

  constructor(
    private actionService: ActionService,
    private campaignService: CampaignService,
  ) { }

  ngOnInit(): void {
    this.campaignMap = [];

    this.campaignService.loadCampaigns();
    this.campaignService.get().subscribe(
      data => {
        this.campaignMap = [];
        for (const campaign of data) {
          if (campaign.status == 1) {
            campaign['selected'] = false;
            this.campaignMap.push(campaign);
          }
        }
      }
    );

    this.actionService.loadActions();
    this.actions$ = this.actionService.get();
  }

  isCampaignMapSet(): boolean {
    return Object.keys(this.campaignMap).length > 0;
  }

  // closure format (with =>) ensures calling `this` in parent's scope
  getCampaignName = (id: any): string => {
    return this.campaignMap[id] || '';
  }

  getCampaignKeys = (): string[] => Object.keys(this.campaignMap);

  saveAction(): void {
    this.actionService.createAction(this.newCampaignName);
  }

  updateAction(action): void {
    this.actionService.updateAction(action);
  }

  deleteAction(id: string): void {
    if (confirm('Diese Aktion wirklich löschen?')) {
      this.actionService.deleteAction(id);
    }
  }
}
