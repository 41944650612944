import { Component, OnInit, ViewChild } from '@angular/core';
import { Campaign } from '../interfaces/campaign';
import { CampaignService } from '../services/campaign.service';
import { Observable } from 'rxjs';
import { NotifierService } from 'angular-notifier';
import { InitService } from '../services/init.service';

@Component({
  selector: 'app-campaign-list',
  templateUrl: './campaign-list.component.html',
  styleUrls: ['./campaign-list.component.scss']
})
export class CampaignListComponent implements OnInit {

  campaigns$: Observable<Campaign[]>;
  agencies$: Observable<object[]>;
  name: string;

  csvSrc: string | ArrayBuffer;

  records: any[] = [];
  @ViewChild('csvFileInput') csvFileInput: any;

  constructor(
    private campaignService: CampaignService,
    private init: InitService,
    private notif: NotifierService
  ) { }

  ngOnInit(): void {
    this.init.loadAgencies();
    this.agencies$ = this.init.getAgencies();

    this.campaignService.loadCampaigns();
    this.campaigns$ = this.campaignService.get();
  }

  create(): void {
    if (!this.name) {
      this.notif.notify('error', 'Bitte Kampagnennamen eingeben');
      return;
    }

    this.campaignService.createCampaign(this.name);

    this.name = ''
  }

  update(campaign: Campaign): void {
    this.campaignService.updateCampaign(campaign);
  }

  delete(id: string): void {
    if (confirm('Diese Kampagne wirklich löschen?')) {
      this.campaignService.deleteCampaign(id);
    }
  }

  childNotif = (str: string): void => {
    this.notif.notify('info', str);
  }

  uploadListener($event: any): void {

    let files = $event.srcElement.files;

    if (this.isValidCSVFile(files[0])) {

      let input = $event.target;
      let reader = new FileReader();
      reader.readAsText(input.files[0]);

      reader.onload = () => {
        let csvData = reader.result;
        let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
        this.records = this.getDataRecordsArrayFromCSVFile(csvRecordsArray);

        //console.log('records', this.records);
        this.campaignService.campaignsImport(this.records);
        this.fileReset();
      };

      reader.onerror = function () {
        console.log('error is occured while reading file!');
      };

    } else {
      alert("Please import valid .csv file.");
      this.fileReset();
    }
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any) {
    let csvArr = [];
    for (let i = 0; i < csvRecordsArray.length; i++) {
      let curruntRecord = (<string>csvRecordsArray[i]).split(',');
      if (curruntRecord.length > 0) {
        csvArr.push(curruntRecord);
      }
    }
    let transpose = csvArr[0].map((x, i) => csvArr.map(x => (x[i] !== "") ? x[i] : ""));
    return transpose;
  }

  isValidCSVFile(file: any) {
    return file.name.endsWith(".csv");
  }

  fileReset() {
    this.csvFileInput.nativeElement.value = "";
    this.records = [];
  }
}
