import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { User } from "../interfaces/user";
import { map } from "rxjs/operators";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userSubject: BehaviorSubject<User>;
  private user: Observable<User>;

  constructor(
    public http: HttpClient,
    private router: Router,
    private cookie: CookieService,
  ) {
    this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
    this.user = this.userSubject.asObservable();
  }

  public get userValue() {
    return this.userSubject.value;
  }

  login(credentials) {
    return this.http.post<User>('/api/login', credentials, { withCredentials: true })
      .pipe(map(user => {
        user['token'] = this.cookie.get('PHPSESSID');
        localStorage.setItem('user', JSON.stringify(user));
        this.userSubject.next(user);

        return user;
      }));
  }

  logout() {
    this.http.get('/api/logout').subscribe(data => {
      localStorage.removeItem('user');
      this.userSubject.next(null);
      this.router.navigate(['/login']);
    });
  }
}
