<form class="action-form-wrapper flex-form" [formGroup]="actionForm">
  <div class="form-column space-between action-text">
    <input type="text" placeholder="Name" formControlName="name" />

    <div class="description-wrapper">
      <textarea placeholder="Beschreibung der Aktion" formControlName="description"></textarea>
    </div>

    <div class="button-row">
      <button class="save-btn" [disabled]="enable" (click)="updateAction($event)">Speichern</button>

      <button title="kopieren" [disabled]="enable" class="clone-btn" (click)="clone()">
        <mat-icon>file_copy</mat-icon>
      </button>

      <button title="Aktion löschen" class="delete-btn" (click)="deleteAction()">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
    <!-- <div class="button-row button-row-bottom">
      <button class="save-btn save-btn-bottom" (click)="openCreateDialog()">Get 'Booked Mailings' Statistic</button>
    </div> -->
  </div>

  <div class="form-column campaigns-loop">

    <div class="action-campaign-field">
      <label class="c-main-title" for="inclusiveMailsCount">Campaign</label>
      <label class="c-main-label" for="inclusiveMailsCount">Choose your campaign</label>
      <select class="c-main-selection" id="inclusiveMailsCount" formControlName="campaign_id">
        <option value="">Select Campaign</option>
        <option *ngFor="let campaign of campaignMap; index as i"
          [selected]="action?.campaigns[0]?.campaign_id === campaign?.id" [value]="campaign?.id">
          {{campaign?.name}}
        </option>
      </select>
    </div>

    <div class="action-campaign-field">
      <label class="c-main-title" for="inclusiveMailsCount">#</label>
      <label class="c-main-label" for="inclusiveMailsCount">How many included mailings?</label>
      <select class="c-main-selection" id="inclusiveMailsCount" formControlName="included_mails">
        <option value="">Select Amount</option>
        <option *ngFor="let number of [1,2,3,4,5,6,7,8,9,10]" [selected]="action?.campaigns[0]?.mails === number"
          [value]="number">{{number}}</option>
      </select>
    </div>

  </div>

  <div class="form-column overflow" formArrayName="mailings">
    <div *ngFor="let mailing of action.mailings; index as j" [formGroupName]="j">

      <div class="field-inline-div">
        <span>{{mailing.name}}</span>
      </div>

      <div class="field-inline-div">
        <label>Pflicht</label>
        <mat-slide-toggle [(ngModel)]="mailing.required" formControlName="required" (change)="changeOption($event)">
        </mat-slide-toggle>
        <label>Optional</label>
      </div>

      <div class="field-inline-div" *ngIf="mailing.required">
        <label>Opt-In</label>
        <mat-slide-toggle [(ngModel)]="mailing.opt_in" formControlName="opt_in" [disabled]="disable"></mat-slide-toggle>
        <label>Opt-Out</label>
      </div>

    </div>
  </div>

</form>