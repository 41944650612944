<div *ngIf="mailing === undefined" mat-dialog-title>
  Mailing erstellen
</div>

<!-- <div class="mailing-items-wrapper-inner" *ngIf="mailing !== undefined"> -->
<div class="mailing-items-wrapper-inner">
  <div class="row">
    <div class="m-form">
      <form class="mailing-form-wrapper" [formGroup]="mailingForm" [class.with-border]="mailing !== undefined">
        <div>
          <label class="invisible" for="mailingName">Titel</label>
          <input type="text" placeholder="Titel" id="mailingName" class="mailing-name" formControlName="name" />
        </div>

        <div class="row">
          <div class="firstC">
            <label for="mailingDateInput">Datum</label>
            <input type="date" id="mailingDateInput" formControlName="date" />
          </div>
          <div class="lastC">
            <label for="mailingTimeInput">Uhrzeit</label>
            <input type="time" id="mailingTimeInput" formControlName="time" />
          </div>
        </div>

        <div>
          <textarea placeholder="Beschreibung" formControlName="description"></textarea>
        </div>

        <div class="row">
          <div class="agency-row">
            <label>Aktionen</label>
            <!-- <ng-multiselect-dropdown [placeholder]="'Aktionen wählen'" [settings]="dropdownSettings" [data]="actions"
              formControlName="actions"></ng-multiselect-dropdown> -->



            <select id="mailingActionInput" formControlName="actions">
              <option [value]="">Aktionen wählen</option>
              <option *ngFor="let action of actions" [selected]="(action_id == action?.id)?true:false"
                [value]="action?.id">
                {{ action?.name }}
              </option>
            </select>

          </div>

          <div class="template-row" *ngIf="templates">
            <label for="mailingTemplateInput">Mailvorlage</label>
            <select id="mailingTemplateInput" formControlName="cleverreach_template">
              <option [value]="">Select Template</option>
              <option *ngFor="let template of templates" [value]="template?.id">
                {{ template?.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="row" *ngIf="mailing === undefined; else updateForm">
          <button (click)="onSave()">Speichern</button>
        </div>

        <ng-template #updateForm>
          <div class="row">
            <button *ngIf="false">Büros</button>
            <button *ngIf="false">Statistik</button>
            <button *ngIf="false">Export</button>
            <button *ngIf="(mailingActive>0)" (click)="onUpdate()" class="save-btn"
              [disabled]="enable">Speichern</button>
            <button *ngIf="(mailingActive===0)" class="save-btn grey-btn">Speichern</button>

            <button title="kopieren" class="clone-btn" [disabled]="enable" (click)="clone()">
              <mat-icon>file_copy</mat-icon>
            </button>

            <button title="Mailing löschen" (click)="onDelete()">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </ng-template>
      </form>
    </div>

    <div class="m-agents" *ngIf="sidebar">
      <table class="mailing-table">
        <thead>
          <tr>
            <th>Participating</th>
            <th>Agencies</th>
            <th>Status</th>
            <th>Included?</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let agency of agent_data">
            <td>{{getAgency(agency).agencyId}}</td>
            <td class="table-name">{{getAgency(agency).name}}</td>
            <td>{{agent_status_data}}</td>
            <td>{{agent_included_data}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="m-total" *ngIf="sidebar">
      <table>
        <thead>
          <tr>
            <th colspan="2">Statistics</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Obligatory participants</td>
            <td>{{(am_required == 0)?agent_data.length:0}}</td>
          </tr>
          <tr>
            <td>Optional participants</td>
            <td>{{(am_required == 1)?agent_data.length:0}}</td>
          </tr>
          <tr>
            <td>Of which not included</td>
            <td>{{(am_optin == 0)?agent_data.length:0}}</td>
          </tr>
          <tr>
            <td>Opted out</td>
            <td>{{(am_optin == 1)?agent_data.length:0}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="m-export" *ngIf="sidebar">
      <button (click)="downloadCSV()" class="btn">Export</button>
    </div>
  </div>
</div>