import { Component, OnInit } from '@angular/core';
import {NavbarService} from '../services/navbar.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {AgencyUser} from '../interfaces/agency-user';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-signout-page',
  templateUrl: './signout-page.component.html',
  styleUrls: ['./signout-page.component.scss']
})
export class SignoutPageComponent implements OnInit {

  userForm: FormGroup = new FormGroup({
    firstName: new FormControl(),
    lastName: new FormControl(),
    email: new FormControl(),
    categories: new FormGroup({
      novelties: new FormControl(),
      developments: new FormControl(),
      eventsAcademy: new FormControl(),
      newsletter: new FormControl(),
      promotion: new FormControl(),
    }),
  });

  isDisabled = false;

  userId;

  constructor(
    public nav: NavbarService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.nav.hide();

    this.route.queryParams
      .subscribe(params => {
        if (params.hasOwnProperty('id') && params.id) {
          this.getData(params.id);
        }
      });
  }

  private getData(id: string): void {
    this.http.get<AgencyUser>(`/api/b2b/newsletter-signout?id=${id}`)
      .subscribe(data => {
        this.userId = data.id;

        this.userForm.setValue({
          firstName: data.firstName || '',
          lastName: data.lastName || '',
          email: data.email || '',
          categories: {
            novelties: data.categories.novelties || false,
            developments: data.categories.developments || false,
            eventsAcademy: data.categories.eventsAcademy || false,
            newsletter: data.categories.newsletter || false,
            promotion: data.categories.promotion || false,
          }
        });
      }, error => {
        this.router.navigate(['/404']);
      }
    );
  }

  setData(e): void {
    e.preventDefault();
    const user = this.userForm.getRawValue();
    user.id = this.userId;

    this.http.put('/api/b2b/newsletter-signout', user)
      .subscribe(
        data => {
          // notify
        }
      );
  }

  toggleDisable(event): void {
    this.isDisabled = event.target.checked;

    if (this.isDisabled) {
      this.userForm.get('categories').get('novelties').disable();
      this.userForm.get('categories').get('developments').disable();
      this.userForm.get('categories').get('eventsAcademy').disable();
      this.userForm.get('categories').get('newsletter').disable();
      this.userForm.get('categories').get('promotion').disable();
    } else {
      this.userForm.get('categories').get('novelties').enable();
      this.userForm.get('categories').get('developments').enable();
      this.userForm.get('categories').get('eventsAcademy').enable();
      this.userForm.get('categories').get('newsletter').enable();
      this.userForm.get('categories').get('promotion').enable();
    }

    this.userForm.patchValue({
      categories: {
        novelties: false,
        developments: false,
        eventsAcademy: false,
        newsletter: false,
        promotion: false,
      }
    });
  }
}
