import { NgModule } from '@angular/core';

import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MAT_RIPPLE_GLOBAL_OPTIONS,
  RippleGlobalOptions
} from "@angular/material/core";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter, MomentDateModule } from "@angular/material-moment-adapter";

import { CookieService } from "ngx-cookie-service";
import { NotifierModule } from "angular-notifier";

import { AppComponent } from './app.component';
import { CampaignListComponent } from './campaign-list/campaign-list.component';
import { ActionListComponent } from './action-list/action-list.component';
import { MailingListComponent } from './mailing-list/mailing-list.component';
import { B2bManagerComponent } from './b2b-manager/b2b-manager.component';
import { B2bMailComponent } from './b2b-mail/b2b-mail.component';
import { MailFormComponent } from './b2b-mail/mail-form/mail-form.component';
import { HeaderComponent } from './header/header.component';
import { SignoutPageComponent } from './signout-page/signout-page.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { CreateMailFormComponent } from "./b2b-mail/dialog-create-mail/create-mail-form.component";
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { CampaignFormComponent } from './campaign-list/campaign-form/campaign-form.component';
import { ActionFormComponent } from './action-list/action-form/action-form.component';
import { MailingFormComponent } from './mailing-list/mailing-form/mailing-form.component';
import { CreateMailingFormComponent } from './mailing-list/create-mailing-form/create-mailing-form.component';

import { HyperSelectorComponent } from './b2b-manager/hyper-selector/hyper-selector.component';
import { AgencyListComponent } from './agency-list/agency-list.component';
import { DraftsListComponent } from './drafts-list/drafts-list.component';
import { AppCommonModule } from './app-common/app-common.module';
import { ActionStatisticsComponent } from './action-list/action-statistics/action-statistics.component';
import { NewsletterFormComponent } from './newsletter-form/newsletter-form.component';


// disable input animations
const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 0,
    exitDuration: 0
  },
};

const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    CampaignListComponent,
    ActionListComponent,
    MailingListComponent,
    B2bManagerComponent,
    B2bMailComponent,
    MailFormComponent,
    HeaderComponent,
    SignoutPageComponent,
    ConfirmDialogComponent,
    CreateMailFormComponent,
    LoginFormComponent,
    CampaignFormComponent,
    ActionFormComponent,
    MailingFormComponent,
    CreateMailingFormComponent,
    HyperSelectorComponent,
    AgencyListComponent,
    DraftsListComponent,
    ActionStatisticsComponent,
    NewsletterFormComponent,
  ],
  imports: [
    AppCommonModule,
    NotifierModule.withConfig({
      theme: 'material',
      position: {
        horizontal: {
          position: 'right'
        }
      },
      behaviour: {
        autoHide: 5000,
      },
    }),    
  ],
  providers: [
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    CookieService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
