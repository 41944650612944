<h2 mat-dialog-title>Entwürfe</h2>

<mat-dialog-content>
  <mat-table [dataSource]="drafts">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef class="mat-column-name">Name</mat-header-cell>
      <mat-cell *matCellDef="let item" class="mat-column-name" title="{{item?.name}}">{{item?.name}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="subject">
      <mat-header-cell *matHeaderCellDef class="mat-column-subject">Betreff</mat-header-cell>
      <mat-cell *matCellDef="let item" class="mat-column-subject" title="{{item?.subject}}">{{item?.subject}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="last_changed">
      <mat-header-cell *matHeaderCellDef class="mat-column-last-changed">Zuletzt geändert</mat-header-cell>
      <mat-cell *matCellDef="let item" class="mat-column-last-changed">{{convertUnix(item?.last_changed)}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="send_on">
      <mat-header-cell *matHeaderCellDef class="mat-column-send-on">Versanddatum</mat-header-cell>
      <mat-cell *matCellDef="let item" class="mat-column-send-on">
        <mat-form-field appearance="fill">
          <input matInput [matDatepicker]="picker" (click)="picker.open()" id="picker-{{item?.id}}">
          <mat-label>DD.MM.YYYY</mat-label>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="send">
      <mat-header-cell *matHeaderCellDef class="mat-column-send"></mat-header-cell>
      <mat-cell *matCellDef="let item" class="mat-column-send">
        <button (click)="publish(item?.id)">Versenden</button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</mat-dialog-content>
