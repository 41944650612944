import {Component, OnInit} from '@angular/core';
import {NavbarService} from "../services/navbar.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UserService} from "../services/user.service";
import {first} from "rxjs/operators";
import {ActivatedRoute, Router} from "@angular/router";
import {NotifierService} from "angular-notifier";

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {

  credentials: FormGroup;

  constructor(
    public nav: NavbarService,
    private user: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private notif: NotifierService
  ) {
    if (this.user.userValue) {
      this.router.navigateByUrl('/b2b-manager');
    }
  }

  ngOnInit(): void {
    this.nav.hideMenu();

    this.credentials = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }

  onLogin() {
    if (this.credentials.invalid) {
      this.notif.notify('error', 'Bitte Benutzernamen und Passwort eingeben.');
      return;
    }

    this.user.login({
      username: this.credentials.get('username').value,
      password: this.credentials.get('password').value,
    })
      .pipe(first())
      .subscribe({
        next: () => {
          const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/b2b-manager';
          this.router.navigateByUrl(returnUrl);
          this.nav.showMenu();
        },
        error: error => {
          console.log(error);
          // todo: alert
        }
      });
  }

}
