import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable } from 'rxjs';
import { Campaign } from "../interfaces/campaign";
import { NotifierService } from 'angular-notifier';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  private _campaigns = new BehaviorSubject<Campaign[]>([]);
  private dataStore: { campaigns: Campaign[] } = { campaigns: [] };

  constructor(
    private http: HttpClient,
    private notif: NotifierService
  ) { }

  get(): Observable<Campaign[]> {
    return this._campaigns.asObservable();
  }

  loadCampaigns(): void {
    this.http.get<Campaign[]>('/api/campaigns').subscribe(
      data => {
        this.dataStore.campaigns = data;
        this._campaigns.next(Object.assign({}, this.dataStore).campaigns);
      },
      error => console.log('Could not load campaigns')
    );
  }

  createCampaign(name: string): void {
    this.http.post('/api/campaigns', { name: name }).subscribe(
      data => {
        if (!data.hasOwnProperty('success') || !data['success']) {
          this.notif.notify('error', 'Die Kampagne konnte nicht angelegt werden');
          return;
        }

        const campaign: Campaign = {
          id: data['id'],
          name,
        };

        this.dataStore.campaigns.push(campaign);
        this._campaigns.next(Object.assign({}, this.dataStore).campaigns);

        this.notif.notify('success', 'Kampagne erfolgreich angelegt');
      },
      error => console.log('Could not create campaign')
    );
  }

  updateCampaign(campaign: Campaign): void {
    const formData: FormData = new FormData();

    formData.append('name', campaign.name);
    // formData.append(''); // todo: logo
    formData.append('logo', campaign.logo);

    formData.append('agencies', campaign.agencies.join(','));

    formData.append('status', campaign.status.toString());

    const options = {
      headers: new HttpHeaders({
        enctype: 'multipart/form-data',
      }),
    };

    this.http.post(`/api/campaigns/${campaign.id}`, formData, options).subscribe(
      (data: any) => {

        if (!data.hasOwnProperty('success') || !data['success']) {
          this.notif.notify('error', 'Speichern fehlgeschlagen');
          return;
        }

        this.notif.notify('success', 'Speichern erfolgreich');

        this.dataStore.campaigns.forEach((c, i) => {
          if (c.id === campaign.id) {
            campaign.image_src = data.image_src
            this.dataStore.campaigns[i] = campaign;
          }
        });

      }
    );
  }

  deleteCampaign(id: string): void {
    this.http.delete(`/api/campaigns/${id}`).subscribe(
      data => {
        if (!data.hasOwnProperty('success') || !data['success']) {
          this.notif.notify('error', 'Löschen fehlgeschlagen');
          return;
        }

        this.notif.notify('success', 'Löschen erfolgreich');

        this.dataStore.campaigns.forEach((c, i) => {
          if (c.id === id) {
            this.dataStore.campaigns.splice(i, 1);
          }

          this._campaigns.next(Object.assign({}, this.dataStore).campaigns);
        });
      },
      error => {
        this.notif.notify('error', 'Löschen fehlgeschlagen');
        console.log('Could not delete campaign');
      }
    );
  }

  campaignsImport(data: any): void {
    this.http.post('/api/campaigns', { data: data }).subscribe(
      data => {
        if (!data.hasOwnProperty('success') || !data['success']) {
          this.notif.notify('error', 'Die Kampagne konnte nicht angelegt werden');
          return;
        }
        this.notif.notify('success', 'All Kampagne imported');

        this.loadCampaigns();
      },
      error => console.log('Could not create campaign')
    );
  }
}
