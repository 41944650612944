import { Component, OnInit } from '@angular/core';
import { QueryBuilderConfig } from "angular2-query-builder";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { CountriesEnum } from "../../enums/countries.enum";

@Component({
  selector: 'hyper-selector',
  templateUrl: './hyper-selector.component.html',
  styleUrls: ['./hyper-selector.component.scss']
})
export class HyperSelectorComponent implements OnInit {

  countries = [];

  query = {};

  config: QueryBuilderConfig = {
    fields: {

    }
  };

  selectedAgencies = [];

  dropdownSettings: IDropdownSettings = {
    selectAllText: 'Alle auswählen',
    unSelectAllText: 'Alle abwählen',
    allowSearchFilter: true,
  };

  agenciesList: object = [
    { id: 1, text: 'Henlo' },
  ];

  selectedCountries = [];

  selectedCriteria: string[] = [];

  constructor() {
    this.countries = Object.entries(CountriesEnum).map(([abbrev, name]) => ({ id: abbrev, text: name }));
  }

  ngOnInit(): void {
  }

  onListSelect($event) {
    console.log(this.selectedAgencies);
    console.log($event);
  }

  onCriterionSelect(keyword: string) {
    if ((keyword || keyword.length > 0) && !this.selectedCriteria.includes(keyword)) {
      this.selectedCriteria.push(keyword);
    }
  }

  onRemove(keyword: string) {
    const index = this.selectedCriteria.indexOf(keyword);

    if (index >= 0) {
      this.selectedCriteria.splice(index, 1);
    }
  }

  onSelectDach(checked: boolean) {
    if (checked) {
      this.selectedCountries.push(
        { id: 'D', text: 'Deutschland' },
        { id: 'A', text: 'Österreich' },
        { id: 'CH', text: 'Schweiz' },
      );
    } else {
      let copy = [...this.selectedCountries];

      copy.forEach((item, index) => {
        console.log(index);
        console.log(item.id);
        console.log(['D', 'A', 'CH'].includes(item.id));
        if (['D', 'A', 'CH'].includes(item.id)) {
          console.log('contains');
          this.selectedCountries.splice(index, 1);
        }
      });
    }
  }

  onSelectBenelux() {
  }

  onSelectEnglishSpeaking() {

  }
}
