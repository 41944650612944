<section class="subsite_wrapper">
  <div class="subsite_header text-center">
    <a href="">
      <img src="/uploads/logo1.png" class="img-fluid" alt="Logo">
    </a>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <form [formGroup]="nsForm" (ngSubmit)="onSave()">
          <p>Wir haben eine Fllle von lnhalten und groBartigen lnformationen fur Sie. egal ob Sie Schmetterl ng-Kunde
            smd oder einfach nur daran 1nteress1ert sind.mehr uber dos Unternehmen und Neuigke ten aus der Reisebranche
            zu erfahren</p>
          <p>Wir mochten Sie nicht mit E-Mails bombordieren,also nehmen Sie sich bitte ein poor Sekunden Zeit. um uns
            lhre Praferenzen mitzuteilen.</p>
          <h2>Aktualisieren Sie lhre Oaten</h2>

          <label class="field-box" for="vomame">Vomame:</label>
          <input type="text" id="vomame" class="field-box" formControlName="vomame">

          <label class="field-box" for="nachname">Nachname:</label>
          <input type="text" id="nachname" class="field-box" formControlName="nachname">

          <label class="field-box" for="emailadresse">Emailadresse</label>
          <input type="text" id="emailadresse" class="field-box" formControlName="emailadresse">

          <label class="field-box">I'd like to receive emails on the following topics:</label>
          <ul class="d-flex flex-wrap ">
            <li class="">
              <div class="form-group">
                <input type="checkbox" id="produktneuheiten" formControlName="produktneuheiten" (change)="checkInput($event,'y')">
                <label for="produktneuheiten">Produktneuheiten</label>
              </div>
            </li>
            <li class="w-50">
              <div class="form-group">
                <input type="checkbox" id="produkt" formControlName="produkt" (change)="checkInput($event,'y')">
                <label for="produkt">Produkt-Weiterentwicklungen</label>
              </div>
            </li>
          </ul>

          <ul class="d-flex flex-wrap ">
            <li>
              <div class="form-group">
                <input type="checkbox" id="schmetterlingvor" formControlName="schmetterlingvor" (change)="checkInput($event,'y')">
                <label for="schmetterlingvor">Schmetterlingvor 9</label>
              </div>
            </li>
            <li>
              <div class="form-group">
                <input type="checkbox" id="promotion" formControlName="promotion" (change)="checkInput($event,'y')">
                <label for="promotion">Promotion</label>
              </div>
            </li>
            <li>
              <div class="form-group">
                <input type="checkbox" id="events" formControlName="events" (change)="checkInput($event,'y')">
                <label for="events">Events & Akademie</label>
              </div>
            </li>
          </ul>

          <label class="field-box">None of the above topics are of interest to me, I'd like to unsubscribe:</label>
          <div class="form-group ">
            <input type="checkbox" id="notes" formControlName="notes" (change)="checkInput($event,'n')">
            <label for="notes">lch méchte keine weiteren E-Mails von Schmetterling erhalten</label>
          </div>
          <button class="btn" type='submit'>EINSTELLUNGEN AKTUALISIEREN</button>
        </form>
      </div>
    </div>
  </div>
</section>