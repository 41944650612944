<div class="content-wrapper master-spacing">
  <div class="mail-forms-header">
    <h3>B2B Manager</h3>
  </div>
  <div class="list-control-bar">
    <form>
      <label for="inputCustomerNumber">Kunden-Nr:</label>

      <select id="inputCustomerNumber" (change)="onChangeView($event.target.value)">
        <option value="0">Alle Büros</option>
        <option *ngFor="let agency of agencies" value="{{agency?.agencyId}}">{{agency.agencyId + ' ' + agency.name}}
        </option>
      </select>

      <label for="inputCustomerNameEmail"></label>

      <mat-form-field floatLabel="never" class="b2b-manager-form-field">
        <input matInput type="text" id="inputCustomerNameEmail" placeholder="Name/Mailadresse eingeben"
          [(ngModel)]="searchValue" [ngModelOptions]="{standalone: true}" (keyup)="doFilter($event.target.value)" />
        <mat-icon matSuffix (click)="doSearchForNameOrEmail()" *ngIf="searchValue.length >= 3">
          search
        </mat-icon>
      </mat-form-field>

    </form>
    <div class="b2b-actions">
      <button class="b2b-action" routerLink="/345345/newsletter">Un-Subscribe</button>
      <button class="b2b-action" (click)="doExport()">Liste exportieren</button>
    </div>
  </div>
  <div class="table-container">
    <mat-table [dataSource]="dataSource" class="list-table">
      <ng-container matColumnDef="agency" sticky>
        <mat-header-cell *matHeaderCellDef>RB</mat-header-cell>
        <mat-cell *matCellDef="let item">{{item?.agency}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="firstName" sticky>
        <mat-header-cell *matHeaderCellDef>Vorname</mat-header-cell>
        <mat-cell *matCellDef="let item">{{item?.firstName}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="lastName" sticky>
        <mat-header-cell *matHeaderCellDef>Nachname</mat-header-cell>
        <mat-cell *matCellDef="let item">{{item?.lastName}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="email" sticky>
        <mat-header-cell *matHeaderCellDef>Mailadresse</mat-header-cell>
        <mat-cell *matCellDef="let item">{{item?.email}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="novelties">
        <mat-header-cell *matHeaderCellDef class="center">Produktneuheiten</mat-header-cell>
        <mat-cell *matCellDef="let item" class="center"><input type="checkbox"
            [checked]="item?.categories?.novelties" />
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="developments">
        <mat-header-cell *matHeaderCellDef class="center">Produkt-Weiterentwicklung</mat-header-cell>
        <mat-cell *matCellDef="let item" class="center"><input type="checkbox"
            [checked]="item?.categories?.developments" /></mat-cell>
      </ng-container>

      <ng-container matColumnDef="eventsAcademy">
        <mat-header-cell *matHeaderCellDef class="center">Events &amp; Akademie</mat-header-cell>
        <mat-cell *matCellDef="let item" class="center"><input type="checkbox"
            [checked]="item?.categories?.eventsAcademy" /></mat-cell>
      </ng-container>

      <ng-container matColumnDef="newsletter">
        <mat-header-cell *matHeaderCellDef class="center">Schmetterlingvor 9</mat-header-cell>
        <mat-cell *matCellDef="let item" class="center"><input type="checkbox"
            [checked]="item?.categories?.newsletter" />
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="promotion">
        <mat-header-cell *matHeaderCellDef class="center">(Fremd-)Promotion</mat-header-cell>
        <mat-cell *matCellDef="let item" class="center"><input type="checkbox"
            [checked]="item?.categories?.promotion" />
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="getDisplayedColumns(); sticky: true"></mat-header-row>

      <mat-row *matRowDef="let row; columns: getDisplayedColumns()" [class.confidential]="row?.isConfidential">
      </mat-row>
    </mat-table>

    <mat-paginator #clientPaginator [hidePageSize]="true" [showFirstLastButtons]="true" [pageSize]="50"
      class="mat-paginator-sticky" [ngStyle]="{display: agency === 0 ? 'none' : 'block' }"></mat-paginator>

    <mat-paginator #serverPaginator [hidePageSize]="true" [showFirstLastButtons]="true" [pageSize]="50"
      [length]="totalLength" (page)="onChangePage($event)" [ngStyle]="{display: agency === 0 ? 'block' : 'none' }"
      class="mat-paginator-sticky"></mat-paginator>

    <ng-template #allAgencies>
    </ng-template>
  </div>
</div>