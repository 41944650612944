<table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="agencyNumber">
        <th mat-header-cell *matHeaderCellDef> Agency Number </th>
        <td mat-cell *matCellDef="let element"> {{element.agencyNumber}} </td>
    </ng-container>

    <ng-container matColumnDef="agencyName">
        <th mat-header-cell *matHeaderCellDef> Agency Name </th>
        <td mat-cell *matCellDef="let element"> {{element.agencyName}} </td>
    </ng-container>

    <ng-container matColumnDef="bookedMailings">
        <th mat-header-cell *matHeaderCellDef> Booked Mailings </th>
        <td mat-cell *matCellDef="let element"> {{element.bookedMailings}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>