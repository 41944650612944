import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Mail } from "../../interfaces/mail";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'mail-form',
  templateUrl: './mail-form.component.html',
  styleUrls: ['./mail-form.component.scss']
})
export class MailFormComponent implements OnInit {

  @Input() mail?: Mail;
  mailForm: FormGroup;
  mailId?: any;

  @Input() templates: object[] = [];
  @Input() receiverLists: object[] = [];

  @Output() saveMail = new EventEmitter<Mail>();
  @Output() updateMail = new EventEmitter<Mail>();
  @Output() deleteMail = new EventEmitter<any>();

  disableUpdate = true;
  reference: object = {};

  constructor(public notif: NotifierService) { }

  ngOnInit(): void {
    this.mailForm = new FormGroup({
      date: new FormControl('', [Validators.required]),
      time: new FormControl('', [Validators.required]),
      template: new FormControl([], [Validators.required]),
      receiverList: new FormControl([], [Validators.required]),
      description: new FormControl('', [Validators.required]),
    });

    if (this.mail !== undefined) {
      this.mailId = this.mail.id;

      const ref = {
        date: this.mail.date.split(' ')[0],
        time: this.mail.date.split(' ')[1],
        template: this.mail.template,
        receiverList: this.mail.receiverList,
        description: this.mail.description,
      };

      this.mailForm.patchValue(ref);
      this.createReference(ref);

      this.mailForm.valueChanges.subscribe(changes => this.isFormDirty(changes));
    }
  }

  private createReference(obj: any) {
    this.reference = Object.assign({}, obj);
  }

  private isFormDirty(currentValue) {
    const fields = ['date', 'time', 'template', 'receiverList', 'description'];

    for (let field of fields) {
      if (currentValue[field] !== this.reference[field]) {
        this.disableUpdate = false;
        return;
      }
    }

    this.disableUpdate = true;
  }

  exportStatistics() {
    console.log('todo');
  }

  showBounces() {
    console.log('todo');
  }

  onSave() {
    if (this.mailForm.invalid) {
      this.notif.notify('error', 'Eingaben sind unvollständig oder ungültig.');
      return;
    }

    this.saveMail.emit({
      date: this.mailForm.get('date').value + ' ' + this.mailForm.get('time').value,
      description: this.mailForm.get('description').value,
      template: this.mailForm.get('template').value,
      receiverList: this.mailForm.get('receiverList').value,
    });

    this.mailForm.reset();
  }

  update() {
    const changes = {
      id: this.mailId,
    };

    Object.keys(this.mailForm.controls).forEach((name) => {
      const currentControl = this.mailForm.controls[name];

      if (currentControl.dirty) {
        // combine date and time into one value
        if (!changes.hasOwnProperty('date') && (name === 'date' || name === 'time')) {
          const date = this.mailForm.controls['date'].dirty
            ? this.mailForm.controls['date'].value
            : this.reference['date'];
          const time = this.mailForm.controls['time'].dirty
            ? this.mailForm.controls['time'].value
            : this.reference['time'];

          changes['date'] = `${date} ${time}`;
          return;
        }

        changes[name] = currentControl.value;
      }
    });

    this.updateMail.emit(changes);
  }

  doDelete() {
    this.deleteMail.emit(this.mailId);
  }

}
