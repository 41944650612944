import { Component, OnInit } from '@angular/core';
import { Moment } from "moment";
import { MatDatepicker } from "@angular/material/datepicker";
import { FormControl } from "@angular/forms";
import * as moment from "moment";
import { MailingService } from "../services/mailing.service";
import { Observable } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { CreateMailingFormComponent } from "./create-mailing-form/create-mailing-form.component";
import { Mailing } from "../interfaces/mailing";
import { ActionService } from '../services/action.service';
import { InitService } from '../services/init.service';
import { DraftsListComponent } from '../drafts-list/drafts-list.component';
import { Draft } from '../interfaces/draft';
import { Action } from '../interfaces/action';
import { Campaign } from '../interfaces/campaign';
import { CampaignService } from '../services/campaign.service';

@Component({
  selector: 'app-mailing-list',
  templateUrl: './mailing-list.component.html',
  styleUrls: ['./mailing-list.component.scss']
})
export class MailingListComponent implements OnInit {

  drafts$: Observable<object[]>;
  drafts: object[];

  mailings$: Observable<Mailing[]>;
  date: FormControl = new FormControl(moment());
  actions: object[];
  actions$: Observable<object[]> = new Observable<object[]>();

  agencies$: Observable<object[]>;
  actionslist$: Observable<Action[]>;
  campaignlist$: Observable<Campaign[]>;

  constructor(
    private mailingService: MailingService,
    private actionService: ActionService,
    private campaignService: CampaignService,
    private initService: InitService,
    private dialog: MatDialog
  ) {

    this.actionService.loadActions();
    this.actions$ = this.actionService.get();
    this.actions$.subscribe((data: any) => {
      this.actions = data;
      this.actionslist$ = data;
    });

    this.initService.loadDrafts();
    this.drafts$ = this.initService.getDrafts();
    this.drafts$.subscribe((data: any) => {
      this.drafts = data;
    });

    this.initService.loadAgencies();
    this.initService.getAgencies().subscribe((data: any) => {
      this.agencies$ = data;
    });

    this.campaignService.loadCampaigns();
    this.campaignService.get().subscribe((data: any) => {
      this.campaignlist$ = data;
    });
  }

  ngOnInit(): void {
    const d = new Date();
    this.getMailings(
      d.getMonth() + 1,
      d.getFullYear()
    );
  }

  getMailings(month: number = 0, year: number = 0): void {
    this.mailingService.loadMailings(month, year);
    this.mailings$ = this.mailingService.get();
  }

  onChosenMonth(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>): void {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    datepicker.close();

    this.getMailings(
      normalizedMonth.month() + 1,
      normalizedMonth.year()
    );
  }

  onArrowClick(change: number): void {
    const ctrlValue = this.date.value;
    if (change === 1) {
      ctrlValue.add(1, 'month');
    } else if (change === -1) {
      ctrlValue.subtract(1, 'month');
    }
    this.date.setValue(ctrlValue);

    this.getMailings(
      ctrlValue.month() + 1,
      ctrlValue.year()
    );
  }

  onSave(newMailing: Mailing): void {
    this.mailingService.createMailing(newMailing);
  }

  onUpdate(mailing: Mailing): void {
    this.mailingService.updateMailing(mailing);
  }

  onDelete(id: string): void {
    if (confirm('Dieses Mailing wirklich löschen? \nMailing is already queued for sending and each Cleverreach-Account has to be checked and cleared for already created Mailings and Recipient-lists.')) {
      this.mailingService.deleteMailing(id);
    }
  }

  openCreateDialog(): void {
    const dialogRef = this.dialog.open(CreateMailingFormComponent, {
      width: '28em',
      data: {
        templates: this.drafts,
        actions: this.actions,
      }
    });

    dialogRef.afterClosed().subscribe((result: Mailing) => {
      if (result) {
        this.onSave(result);
      }
    });
  }

  openDrafts(): void {
    const dialogRef = this.dialog.open(DraftsListComponent, {
      width: '70em',
      data: {
        drafts: this.drafts,
      }
    });
  }
}
